import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard, MdHelpOutline } from "react-icons/md";

import Widget from "components/widget/Widget";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AiFillDollarCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { FaEnvelope, FaHourglassHalf, FaUser, FaUserTie } from "react-icons/fa";
import { motion } from "framer-motion";
import { RiMessage2Line } from "react-icons/ri";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "../default/variables/columnsData";
import CheckTable from "../default/components/CheckTable";
import DailyTraffic from "../default/components/DailyTraffic";
import TaskCard from "../default/components/TaskCard";
import ComplexTable from "../default/components/ComplexTable";
import userResources from "api/userResources";
const HotelStatistics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  console.log(location?.state, "paras");
  console.log(data, "parasdata");

  const dashboardData = async () => {
    try {
      let response;
      if (location?.state?.hotelprofileData) {
        // Call the profileStatics API if ProfileData is true
        response = await userResources.hotelprofileStatics();
      } else {
        // Call the dashboardStatics API otherwise
        response = await userResources.hoteldashboardStatics();
      }
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (location?.state?.ProfileModalData) {
      setData(location?.state?.ProfileModalData?.hotelStatics);
    } else {
      dashboardData();
    }
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1, // Delay between child animations
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  return (
    <section>
      {data && (
        <div>
          <div>
            {data?.totalBudget >= 0 ||
            data?.totalPaidAmount >= 0 ||
            data?.pendingAmount >= 0 ||
            data?.overPaidAmount >= 0 ||
            data?.notPaidAmount ? (
              <div>
                {/* Wrap the entire grid with the motion.div to apply container animation */}
                <h2 className="my-4 text-xl font-semibold text-gray-800 dark:text-white">
                  Financial Overview
                </h2>
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6"
                >
                  {/* Each widget is wrapped in a motion.div for child animation */}
                  {data?.totalBudget >= 0 ? (
                    <motion.div variants={cardVariants}>
                      <Widget
                        icon={
                          <AiFillDollarCircle className="h-7 w-7 text-blue-500 dark:text-white" />
                        }
                        heading={"Total Quote Price"}
                        subtitle={`$${data?.totalBudget || 0}`}
                        cardbgColor={"bg-blue-500 cursor-pointer"}
                        titleColor={"text-white"}
                      />
                    </motion.div>
                  ) : (
                    ""
                  )}
                  {data?.totalPaidAmount >= 0 ? (
                    <motion.div variants={cardVariants}>
                      <Widget
                        icon={
                          <AiFillDollarCircle className="h-7 w-7 text-green-800 dark:text-white" />
                        }
                        heading={"Total Paid Amount"}
                        subtitle={`$${data?.totalPaidAmount || 0}`}
                        cardbgColor={"bg-green-800"}
                        titleColor={"text-white"}
                        // navigateTo={
                        //   location?.state?.assignListNavigate ||
                        //   location?.state?.assignListNavigate ||
                        //   "/admin/hotel-enquirys"
                        // }
                        // id={"paid"}
                        // name={"Paid"}
                        // thisSearchFor={{
                        //   name: "enquiryPaymentStatus",
                        // }}
                      />
                    </motion.div>
                  ) : null}
                  {data?.pendingAmount >= 0 ? (
                    <motion.div variants={cardVariants}>
                      <Widget
                        icon={
                          <AiFillDollarCircle className="h-7 w-7 text-pink-500 dark:text-white" />
                        }
                        heading={"Pending Amount"}
                        subtitle={`$${data?.pendingAmount || 0}`}
                        cardbgColor={"bg-pink-500 cursor-pointer"}
                        titleColor={"text-white"}
                        navigateTo={
                          location?.state?.assignListNavigate ||
                          "/admin/hotel-enquirys"
                        }
                        id={"pending"}
                        name={"Pending"}
                        thisSearchFor={{
                          name: "enquiryPaymentStatus",
                        }}
                        filterWithEmployee={{
                          name: location?.state?.ProfileModalData?.name,
                          id: location?.state?.ProfileModalData?.id,
                          image: location?.state?.ProfileModalData?.full_path,
                        }}
                      />
                    </motion.div>
                  ) : null}
                  {data?.overPaidAmount >= 0 ? (
                    <motion.div variants={cardVariants}>
                      <Widget
                        icon={
                          <AiFillDollarCircle className="h-7 w-7 text-green-500 dark:text-white" />
                        }
                        heading={"Overpaid Amount"}
                        subtitle={`$${data?.overPaidAmount || 0}`}
                        cardbgColor={"bg-green-500 cursor-pointer"}
                        titleColor={"text-white"}
                        navigateTo={
                          location?.state?.assignListNavigate ||
                          "/admin/hotel-enquirys"
                        }
                        id={"over_paid"}
                        name={"OverPaid"}
                        thisSearchFor={{
                          name: "enquiryPaymentStatus",
                        }}
                        filterWithEmployee={{
                          name: location?.state?.ProfileModalData?.name,
                          id: location?.state?.ProfileModalData?.id,
                          image: location?.state?.ProfileModalData?.full_path,
                        }}
                      />
                    </motion.div>
                  ) : (
                    ""
                  )}

                  {data?.notPaidAmount >= 0 ? (
                    <motion.div
                      variants={cardVariants}
                      // onClick={() =>
                      //   navigate(
                      //     location?.state?.assignListNavigate ||
                      //       "/admin/hotel-enquirys"
                      //   )
                      // }
                    >
                      <Widget
                        icon={
                          <RiMessage2Line className="h-7 w-7 text-red-500 dark:text-white" />
                        }
                        heading={"Total Not Paid Amount"}
                        subtitle={`${data?.notPaidAmount || 0}`}
                        cardbgColor={"bg-red-500 cursor-pointer"}
                        titleColor={"text-white"}
                        id={"not_paid"}
                        name={"Not Paid"}
                        thisSearchFor={{
                          name: "enquiryPaymentStatus",
                        }}
                        navigateTo={
                          location?.state?.assignListNavigate ||
                          "/admin/hotel-enquirys"
                        }
                        filterWithEmployee={{
                          name: location?.state?.ProfileModalData?.name,
                          id: location?.state?.ProfileModalData?.id,
                          image: location?.state?.ProfileModalData?.full_path,
                        }}
                      />
                    </motion.div>
                  ) : null}
                </motion.div>
              </div>
            ) : (
              ""
            )}
          </div>
          {data?.totalHotelEnquiries >= 0 ||
          data?.hisCreatedEnquiries >= 0 ||
          data?.pendingHotelEnquiries >= 0 ||
          data?.assigned_enquiries ? (
            <div>
              <h2 className="my-4 text-xl font-semibold text-gray-800 dark:text-white">
                Enquiry Management
              </h2>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6"
              >
                {data?.totalHotelEnquiries >= 0 ? (
                  <motion.div
                    variants={cardVariants}
                    onClick={() =>
                      navigate(
                        location?.state?.assignListNavigate ||
                          "/admin/hotel-enquirys"
                      )
                    }
                  >
                    <Widget
                      icon={
                        <RiMessage2Line className="h-7 w-7 text-purple-500 dark:text-white" />
                      }
                      heading={"Total Enquiries"}
                      subtitle={`${data?.totalHotelEnquiries || 0}`}
                      cardbgColor={"bg-purple-500 cursor-pointer"}
                      titleColor={"text-white"}
                      // navigateTo={location?.state?.assignListNavigate || "/admin/hotel-enquirys"}
                    />
                  </motion.div>
                ) : null}
                {data?.hisCreatedEnquiries >= 0 ? (
                  <motion.div variants={cardVariants}>
                    <Widget
                      icon={
                        <RiMessage2Line className="h-7 w-7 text-yellow-500 dark:text-white" />
                      }
                      heading={"Created Enquiries"}
                      subtitle={`${data?.hisCreatedEnquiries || 0}`}
                      cardbgColor={"bg-yellow-500 cursor-pointer"}
                      titleColor={"text-white"}
                      navigateTo={
                        location?.state?.createdListNavigate ||
                        "/admin/hotel-enquirys"
                      }
                      name={"Created"}
                      thisSearchFor={{
                        name: "pendingEnquirys",
                      }}
                      filterWithEmployee={{
                        name: location?.state?.ProfileModalData?.name,
                        CreatedUserid: location?.state?.ProfileModalData?.id,
                        image: location?.state?.ProfileModalData?.full_path,
                      }}
                    />
                  </motion.div>
                ) : null}
                {data?.assigned_enquiries >= 0 ? (
                  <motion.div variants={cardVariants}>
                    <Widget
                      icon={
                        <RiMessage2Line className="h-7 w-7 text-purple-500 dark:text-white" />
                      }
                      heading={"Assigned Enquiries"}
                      subtitle={`${data?.assigned_enquiries || 0}`}
                      cardbgColor={"bg-purple-500 cursor-pointer"}
                      titleColor={"text-white"}
                      navigateTo={
                        location?.state?.assignListNavigate ||
                        "/admin/hotel-enquirys"
                      }
                      name={"Assigned"}
                      thisSearchFor={{
                        name: "pendingEnquirys",
                      }}
                      filterWithEmployee={{
                        name: location?.state?.ProfileModalData?.name,
                        id: location?.state?.ProfileModalData?.id,
                        image: location?.state?.ProfileModalData?.full_path,
                      }}
                    />
                  </motion.div>
                ) : null}
                {data?.pendingHotelEnquiries >= 0 ? (
                  <motion.div variants={cardVariants}>
                    <Widget
                      icon={
                        <RiMessage2Line className="h-7 w-7 text-red-500 dark:text-white" />
                      }
                      heading={"Pending Enquiries"}
                      subtitle={`${data?.pendingHotelEnquiries || 0}`}
                      cardbgColor={"bg-red-500 cursor-pointer"}
                      titleColor={"text-white"}
                      navigateTo={
                        location?.state?.assignListNavigate ||
                        "/admin/hotel-enquirys"
                      }
                      id={"pending"}
                      name={"Pending"}
                      thisSearchFor={{
                        name: "pendingEnquirys",
                      }}
                      filterWithEmployee={{
                        name: location?.state?.ProfileModalData?.name,
                        id: location?.state?.ProfileModalData?.id,
                        image: location?.state?.ProfileModalData?.full_path,
                      }}
                    />
                  </motion.div>
                ) : null}
              </motion.div>
            </div>
          ) : (
            ""
          )}
          <div>
            {data?.totalSuperAdmins >=0 ||
            data?.totalAdmins >=0 ||
            data?.totalEmployees >=0 ||
            data?.totalUsers ? (
              <h2 className="my-4 text-xl font-semibold text-gray-800 dark:text-white">
                User and Employee Insights
              </h2>
            ) : (
              ""
            )}
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6"
            >
              {data?.totalSuperAdmins >= 0 ? (
                <motion.div variants={cardVariants}>
                  <Widget
                    icon={
                      <FaUserTie className="h-7 w-7 text-blue-600 dark:text-white" />
                    }
                    heading={"Total Super Admin"}
                    subtitle={`${data?.totalSuperAdmins || 0}`}
                    cardbgColor={"bg-blue-600  cursor-pointer"}
                    titleColor={"text-white "}
                    navigateTo={"/admin/employee-list"}
                  />
                </motion.div>
              ) : null}
              {data?.totalAdmins >= 0 ? (
                <motion.div variants={cardVariants}>
                  <Widget
                    icon={
                      <FaUserTie className="h-7 w-7 text-amber-500 dark:text-white" />
                    }
                    heading={"Total Admin"}
                    subtitle={`${data?.totalAdmins || 0}`}
                    cardbgColor={"bg-amber-500  cursor-pointer"}
                    titleColor={"text-white "}
                    navigateTo={"/admin/employee-list"}
                  />
                </motion.div>
              ) : null}
              {data?.totalEmployees >= 0 ? (
                <motion.div variants={cardVariants}>
                  <Widget
                    icon={
                      <FaUserTie className="h-7 w-7 text-indigo-900 dark:text-white" />
                    }
                    heading={"Total Employees"}
                    subtitle={`${data?.totalEmployees || 0}`}
                    cardbgColor={"bg-indigo-900  cursor-pointer"}
                    titleColor={"text-white "}
                    navigateTo={"/admin/employee-list"}
                  />
                </motion.div>
              ) : null}
              {data?.totalUsers >= 0 ? (
                <motion.div variants={cardVariants}>
                  <Widget
                    icon={
                      <FaUserTie className="h-7 w-7 text-purple-500 dark:text-white" />
                    }
                    heading={"Total Website Users"}
                    subtitle={`${data?.totalUsers || 0}`}
                    cardbgColor={"bg-purple-500 cursor-pointer"}
                    titleColor={"text-white"}
                  />
                </motion.div>
              ) : null}
            </motion.div>
          </div>
          {/* Charts */}
          {/* 
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

          {/* Tables & Charts */}

          <div className="mt-5">
            {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            // tableData={tableDataCheck}
          />
        </div> */}

            <div className="grid grid-cols-1 items-center gap-5 sm:mt-0 md:grid-cols-2">
              {/* <DailyTraffic /> */}
              {data?.enquirySourceCount && (
                <PieChartCard
                  heading={"Enquiry Source Status"}
                  data={data?.enquirySourceCount}
                  name="enquiry_source"
                  navigateTo={
                    location?.state?.assignListNavigate ||
                    "/admin/hotel-enquirys"
                  }
                  lastName="name"
                  colors={[
                    "#eab308",
                    "#f53939",
                    "#22c55e",
                    "#312e81",
                    "#ff0080",
                    "#a855f7",
                    "#ff0080",
                    "#00bcd4",
                    "#8bc34a",
                  ]} // Define your colors
                  thisSearchFor={{
                    name: "enquirySourceStatus",
                  }}
                  filterWithEmployee={{
                    name: location?.state?.ProfileModalData?.name,
                    id: location?.state?.ProfileModalData?.id,
                    image: location?.state?.ProfileModalData?.full_path,
                  }}
                />
              )}
              {data?.enquiryPaymentStatus && (
                <PieChartCard
                  heading={"Enquiry Payment Status"}
                  data={data?.enquiryPaymentStatus}
                  name="enquiryPaymentStatus"
                  navigateTo={
                    location?.state?.assignListNavigate ||
                    "/admin/hotel-enquirys"
                  }
                  lastName="enquiry_payment_status"
                  colors={["#eab308", "#22c55e", "#008000", "#f53939"]} // Define your colors
                  thisSearchFor={{
                    name: "enquiryPaymentStatus",
                  }}
                  filterWithEmployee={{
                    name: location?.state?.ProfileModalData?.name,
                    id: location?.state?.ProfileModalData?.id,
                    image: location?.state?.ProfileModalData?.full_path,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default HotelStatistics;
