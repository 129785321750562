import axiosInstance from "./base";

const userResources = {
  // login: async (loginData) => {
  //   try {
  //     const response = await axiosInstance.post("/login", loginData);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  userVerify: (id) => {
    return axiosInstance.get(`/user/verify/${id}`);
  },
  login: (data) => {
    return axiosInstance.post("/login", data);
  },
  register: (data) => {
    return axiosInstance.post("/register", data);
  },
  employeeRegister: (data) => {
    return axiosInstance.post("/user/employee-register", data);
  },
  logout: () => {
    return axiosInstance.get("/user/logout");
  },
  role: () => {
    return axiosInstance.get("/roles/list");
  },
  updateprofile: (data, token) => {
    return axiosInstance.post("/user/update-user-details", data, {
      headers: {
        Authorization: `Bearer ${token}`, // Attach the token for authentication
      },
    });
  },
  createEnquiry: (data) => {
    return axiosInstance.post("/enquiries/save-and-update", data);
  },
  createEnquiryMultiWay: (data) => {
    return axiosInstance.post("/enquiries/save-and-update-multiple", data);
  },
  createEnquiryMultiWayByAgent: (data) => {
    return axiosInstance.post(
      "/assign-enquiries/save-and-update-multiple",
      data
    );
  },
  createEnquiryRoundAndOneWayByAgent: (data) => {
    return axiosInstance.post("/assign-enquiries/save-and-update", data);
  },
  createEnquiryMulticityByAgent: (data) => {
    return axiosInstance.post(
      "/assign-enquiries/save-and-update-multiple",
      data
    );
  },

  enquiryList: (params) => {
    const url = "/enquiries/list";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },
  enquiryListAll: (params) => {
    const url = "/enquiries/all-enquiries";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },
  searchEnquiryList: (data) => {
    return axiosInstance.post("/enquiries/search-flight", data);
  },

  enquiryAssignList: (params) => {
    const url = "/assign-enquiries/list";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },
  assignEnquiryAccepter: (data) => {
    const params = {
      status: data.status,
    };
    return axiosInstance.post(`/enquiries/accept-or-reject/${data.id}`, params);
  },
  enquiryDelete: (id) => {
    return axiosInstance.delete(`/enquiries/delete/${id}`);
  },
  userList: ({ role_id, page = 0, size = 10, globalFilter }) => {
    let url = "/user/user-list";
    const params = [];

    // Add role_id if provided
    if (role_id) {
      params.push(`role_id=${role_id.value || role_id}`);
    }

    // Add pagination defaults if not provided
    params.push(`page=${page + 1}`); // Assuming 1-based page index for API
    params.push(`limit=${size}`);

    // Add global filter if provided
    if (globalFilter) {
      params.push(`email=${globalFilter}`);
    }

    // Append all params to the URL
    if (params.length) {
      url += `?${params.join("&")}`;
    }
    return axiosInstance.get(url);
  },
  adminemployeeslist: (page = 0, size = 9999) => {
    let url = "/user/admin-employee-list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }
    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  userDelete: (id) => {
    return axiosInstance.delete(`/user/delete/${id}`);
  },
  addAirlines: (data) => {
    return axiosInstance.post("/air-lines/save-and-update", data);
  },
  // Assuming userResources is already defined and axiosInstance is configured
  airlines: (page = 0, size = 9999, globalFilter) => {
    let url = "/air-lines/list";
    const params = [];

    // Since page and size have default values, they’ll be used if not provided
    params.push(`page=${page + 1}`);
    params.push(`limit=${size}`);

    if (globalFilter) {
      // Check for a non-empty string here
      params.push(`airline_name=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },

  airlinesDelete: (id) => {
    return axiosInstance.delete(`/air-lines/delete/${id}`);
  },
  departments: (page = 0, size = 9999, globalFilter) => {
    let url = "/departments/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }

    if (globalFilter !== undefined) {
      params.push(`departments=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  addDepartments: (data) => {
    return axiosInstance.post("/departments/save-and-update", data);
  },
  departmentDelete: (id) => {
    return axiosInstance.delete(`/departments/delete/${id}`);
  },

  branch: (page = 0, size = 9999, globalFilter) => {
    let url = "/branches/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }

    if (globalFilter !== undefined) {
      params.push(`departments=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  addBranch: (data) => {
    return axiosInstance.post("/branches/save-and-update", data);
  },
  branchDelete: (id) => {
    return axiosInstance.delete(`/branches/delete/${id}`);
  },
  createStatus: (data) => {
    return axiosInstance.post("/enquiries-status/save-and-update", data);
  },
  enquityStatus: (page = 0, size = 9999, globalFilter) => {
    let url = "/enquiries-status/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }
    if (globalFilter !== undefined) {
      params.push(`enquiries-source=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  enquiryStatusDelete: (id) => {
    return axiosInstance.delete(`/enquiries-status/delete/${id}`);
  },
  createSource: (data) => {
    return axiosInstance.post("/enquiries-source/save-and-update", data);
  },
  sourceDelete: (id) => {
    return axiosInstance.delete(`/enquiries-source/delete/${id}`);
  },
  getSources: (page = 0, size = 9999, globalFilter) => {
    let url = "/enquiries-source/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }
    if (globalFilter !== undefined) {
      params.push(`enquiries-source=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  addHotel: (data) => {
    return axiosInstance.post("/hotel-enquire/save-and-update", data);
  },
  hotelList: (params) => {
    const url = "/hotel-enquire/list";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },
  hotelEnquiryDelete: (id) => {
    return axiosInstance.delete(`/hotel-enquire/delete/${id}`);
  },
  hotelAssignEnquiryAccepter: (data) => {
    const params = {
      status: data.status,
    };
    return axiosInstance.post(
      `/hotel-enquire/accept-or-reject/${data.id}`,
      params
    );
  },
  hotelEnquiryAssignList: (params) => {
    const url = "/assign-hotel-enquiries/list";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },
  searchHotelEnquiryList: (data) => {
    return axiosInstance.post("/hotel-enquire/search-hotel-enquiry", data);
  },
  createAssignedEnquirys: (data) => {
    return axiosInstance.post("/assign-hotel-enquiries/save-and-update", data);
  },
  createdHotelEnquiryList: (params) => {
    const url = "/hotel-enquire/created-by-employee";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },

  dashboardStatics: () => {
    return axiosInstance.post("/statics/list");
  },
  hoteldashboardStatics: () => {
    return axiosInstance.get("/statics/hotel-list");
  },
  hotelprofileStatics: () => {
    return axiosInstance.get("/statics/profile-hotel-enquires");
  },

  profileStatics: () => {
    return axiosInstance.get("/statics/profile-statics");
  },
  markAttendance: () => {
    return axiosInstance.get("/attendance/mark-attendance");
  },
  getAttendance: () => {
    return axiosInstance.get("/attendance/list");
  },
  createInvoice: (data) => {
    return axiosInstance.post("/invoices/save-and-update", data);
  },
  getinvoiceData: () => {
    return axiosInstance.get("/invoices/list");
  },
  notification: () => {
    return axiosInstance.get("/notifications/list");
  },
};

export default userResources;
