// base.jsx
import axios from "axios";
import config from "./Confiq";
import store from "../redux/Store";
import { login, logout, setUserInfo } from "../redux/userSlice";

const HOST_URL = config.api_url;

const headers = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

const instance = axios.create({
  baseURL: HOST_URL,
  headers: headers,
});

instance.interceptors.request.use(
  async function (conf) {
    const state = store.getState();
    // console.log(state, "state");
    const userToken = state?.user?.userInfo?.data?.plain_text_token;

    let params = conf.params || {};

    if (conf.params) {
      params = conf.params;
    }
    if (userToken) {
      conf.headers.Authorization = `Bearer ${userToken}`;
    }

    conf.params = params;

    return conf;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const { data } = response;

    // Handle login response and update Redux store
    if (data.token && data.role) {
      store.dispatch(login(true));
      store.dispatch(
        setUserInfo({ plain_text_token: data.token, role: data.role })
      );
    }

    return response;
  },
  (error) => {
    const responseStatus = error?.response?.status;

    if (responseStatus === 401) {
      store.dispatch(logout());
      store.dispatch(login(false));
      localStorage.clear();
    } else {
      console.error("Unhandled error:", error);
    }

    return Promise.reject(error);
  }
);

export default instance;
