// Function to determine text color based on status
const getStatusTextColor = (status) => {
  switch (status) {
    case "accept":
      return "text-green-700"; // Green for Accepted
    case "pending":
      return "text-gray-400"; // Gray for Pending
    case "reject":
      return "text-red-600"; // Red for Rejected
    default:
      return "text-gray-600"; // Default gray for unknown statuses
  }
};

export default getStatusTextColor;
