import userResources from "api/userResources";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/userSlice";
import { avtar } from "utils/images";
import { FcCallback } from "react-icons/fc";
import { whatsAppicon } from "utils/images";
import { gmailIcon } from "utils/images";
import Widget from "components/widget/Widget";
import { FaHotel, FaPlane } from "react-icons/fa";
import Dashboard from "views/admin/default";

export default function UserInfoModal({ user, staticsShow, onClose }) {
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const userInfo = useSelector(selectUserInfo);

  const getDepartment = async () => {
    try {
      const response = await userResources.departments(); // Replace with your API endpoint
      setDepartmentData(response.data.data);
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  useEffect(() => {
    if (user && departmentData.length > 0) {
      const department = departmentData.find(
        (dept) => dept.id === user.department_id
      );
      setDepartmentName(department ? department.name : null);
    }
  }, [user, departmentData]);

  return (
    <section>
      <div
        className={`hide-scroll-bar fixed inset-0 m-0 h-screen w-full transform overflow-hidden bg-white p-0 shadow-lg sm:modal-box sm:left-1/2 sm:top-1/2 ${
          staticsShow ? "sm:h-[80%]" : "sm:h-[36%]"
        } sm:w-full sm:-translate-x-1/2 sm:-translate-y-1/2 sm:rounded-3xl`}
        style={{ zIndex: 50, padding: 0 }}
      >
        <div className="h-36 bg-gray-900">
          <button
            className="absolute right-4 top-4 z-10 text-red-600 hover:text-red-800"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
        <div className="absolute top-2 flex w-full items-end justify-between px-5">
          <div>
            <img
              src={user?.full_path}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = avtar; // Default avatar
              }}
              // alt={user?.name}
              className="mb-1 h-24 w-24 rounded-full border-4 shadow-md"
            />
            <h3 className="text-center text-2xl font-semibold capitalize text-white">
              {user?.name}
            </h3>
          </div>
          {userInfo?.data?.id === user?.id ||
          userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_SUPERADMIN) ||
          userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_ADMIN) ||
          userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_EMPLOYEE) ? (
            <div>
              {user?.status && (
                <p
                  className={`${
                    user?.status === "active"
                      ? "text-green-500"
                      : "text-red-500 "
                  } flex items-center gap-2`}
                >
                  <span className="relative top-0.5 h-3 w-3 rounded-full bg-current"></span>
                  {user?.status}
                </p>
              )}
            </div>
          ) : null}
          <div className="relative -top-2 flex items-center gap-2">
            <a
              href={`https://wa.me/${user?.phone_no}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsAppicon} alt="WhatsApp" width={30} />
            </a>
            <a
              href={`tel:${user?.phone_no}`}
              className="rounded-full bg-white p-1"
            >
              <FcCallback size={22} className="cursor-pointer" />
            </a>
            <a
              href={`mailto:${user?.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full bg-white p-1"
            >
              <img src={gmailIcon} alt="Gmail" width={22} />
            </a>
          </div>
        </div>
        <div className="hide-scroll-bar overflow-y-scroll bg-white dark:bg-gray-900">
          <div className="flex items-start justify-between p-4">
            <div>
              <div>
                <label htmlFor="">Email:</label>
                <p className="m-0 p-0 text-gray-900 dark:text-white">
                  {user?.email}
                </p>
              </div>
              {user?.phone_no && (
                <div>
                  <label htmlFor="">Phone:</label>
                  <p className="m-0 p-0 text-gray-900 dark:text-white">
                    {user?.phone_no}
                  </p>
                </div>
              )}
            </div>
            <div>
              {userInfo?.data?.id === user?.id ||
              userInfo?.data?.role_id ===
                Number(process.env.REACT_APP_ROLE_SUPERADMIN) ||
              userInfo?.data?.role_id ===
                Number(process.env.REACT_APP_ROLE_ADMIN) ? (
                <div>
                  <div className="flex items-center justify-between">
                    {user?.age && (
                      <div>
                        <label htmlFor="">Age:</label>
                        <p className="text-gray-900 dark:text-white">
                          {user?.age}
                        </p>
                      </div>
                    )}
                    {user?.salary && (
                      <div>
                        <label htmlFor="">Salary:</label>
                        <p className="text-gray-900 dark:text-white">
                          {user?.salary}
                        </p>
                      </div>
                    )}
                  </div>
                  {departmentName && (
                    <div>
                      <label htmlFor="">Department:</label>
                      <p className="text-gray-900 dark:text-white">
                        {departmentName}
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          {staticsShow === true ? (
            <div>
              <Dashboard
                ProfileModalData={{ ProfileModalData: user }}
                profileModalClassName={true}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}
