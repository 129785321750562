import React, { useEffect, useState, useMemo } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Lock, Person } from "@mui/icons-material";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import userResources from "api/userResources";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import SubmitButton from "components/SubmitButton/SubmitButton";

export default function Employee() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const getEmployeesList = async () => {
    const params = {
      role_id: Number(process.env.REACT_APP_ROLE_EMPLOYEE),
      page: pagination.pageIndex, // Adjusting to match `page` in `userList`
      size: pagination.pageSize, // Adjusting to match `size` in `userList`
      globalFilter,
    };
    setIsLoading(true);

    try {
      const response = await userResources.userList(params);
      setData(response?.data?.data || []);
      setTotalRows(response.data.paginate_data.total || 0);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const loadingToastId = toast.loading("Deleting Employee...");
    try {
      await userResources.userDelete(id);
      toast.update(loadingToastId, {
        render: "Employee deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      getEmployeesList();
    } catch (error) {
      toast.update(loadingToastId, {
        render: error.response?.data?.message || "Failed to delete employee.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserProfile({}); // Reset user profile on close
  };

  const toggleStatus = async (id) => {
    const loadingToastId = toast.loading("Processing...");
    try {
      const res = await userResources.userVerify(id);
      getEmployeesList();
      toast.update(loadingToastId, {
        render: res.data.message || "Status updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(loadingToastId, {
        render: error.message || "Error updating status.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const columns = useMemo(
    () => [
      { accessorKey: "id", header: "ID" },
      { accessorKey: "name", header: "Employee Name" },
      { accessorKey: "email", header: "Email" },
      { accessorKey: "phone_no", header: "Extension Number" },
      {
        accessorKey: "role_id",
        header: "Role",
        Cell: ({ cell }) => {
          const roleMap = {
            [process.env.REACT_APP_ROLE_ADMIN]: "Admin",
            [process.env.REACT_APP_ROLE_EMPLOYEE]: "Employee",
            [process.env.REACT_APP_ROLE_SUPERADMIN]: "Super Admin",
            [process.env.REACT_APP_ROLE_USER]: "User",
          };
          return roleMap[cell.getValue()] || "N/A";
        },
      },
      {
        accessorKey: "branch",
        header: "Branch",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "department",
        header: "Department",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "created_at",
        header: "Created at",
        Cell: ({ cell }) =>
          new Date(cell.getValue()).toISOString().split("T")[0],
      },
      { accessorKey: "salary", header: "Salary" },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            className="toggle toggle-success"
            checked={row.original.status === "active"}
            onChange={() => toggleStatus(row.original.id)}
            style={{ cursor: "pointer" }}
          />
        ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Edit
              className="cursor-pointer text-blue-500"
              // onClick={handleEdit}
            />
            {/* <Lock className="cursor-pointer text-yellow-500" /> */}
            <Person
              className="cursor-pointer text-green-500"
              onClick={() => handleOpenModal(row.original)} // Wrap in an arrow function
            />
            <ConfirmModal
              onConfirm={() => handleDelete(row.original.id)}
              title="Delete Employee"
              message="Are you sure you want to delete this Employee?"
            />
          </div>
        ),
      },
    ],
    []
  );

  const sendEmailToAll = () => {
    const emails = data.map((employee) => employee.email).join(",");
    window.open(`mailto:${emails}?subject=Hello Everyone!&body=Hi Team,%0A%0A`);
  };

  const handleGlobalFilterChange = (value) => setGlobalFilter(value);

  useEffect(() => {
    getEmployeesList();
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) =>
    setPagination(updatedPagination);

  return (
    <section className="mx-2 mt-0 sm:mt-5">
      <ScrollToTopOnRouteChange />
      <div className="mb-4 flex gap-4">
        <SubmitButton
          onClick={sendEmailToAll}
          add="Send Email to All"
          extraClass={
            "h-full bg-green-500 hover:bg-green-700 rounded-[20px] mt-2 sm:mt-0"
          }
        />
      </div>
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ pagination, isLoading }}
        enablePagination
        onPaginationChange={handlePaginationChange}
        manualPagination
        rowCount={totalRows}
        pageCount={Math.ceil(totalRows / pagination.pageSize)}
        enableGlobalFilter
        onGlobalFilterChange={handleGlobalFilterChange}
        globalFilter={globalFilter}
      />
      {/* Modal for User Info */}
      <div className="">
        {isModalOpen && (
          <UserInfoModal
            user={userProfile}
            staticsShow={true}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </section>
  );
}
