import React from 'react'
import HotelList from './HotelList'

export default function HotelCreatedEnquiryList() {
  return (
    <section>
      <HotelList api="hotelcreatedenquiryList" />
    </section>
  )
}
