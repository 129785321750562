import React from "react";
import { useNavigate } from "react-router-dom";

const GoBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Goes to the previous page
  };

  return (
    <button
      onClick={handleGoBack}
      className="rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
    >
      Go Back
    </button>
  );
};

export default GoBackButton;
