import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { selectUserInfo } from "../../redux/userSlice";
import { useSelector } from "react-redux";

export default function Admin(props) {
  const userInfo = useSelector(selectUserInfo);
  const ROLE_EMPLOYEE = Number(process.env.REACT_APP_ROLE_EMPLOYEE);
  const ROLE_USER = Number(process.env.REACT_APP_ROLE_USER);
  const ROLE_ADMIN = Number(process.env.REACT_APP_ROLE_ADMIN);
  const ROLE_SUPERADMIN = Number(process.env.REACT_APP_ROLE_SUPERADMIN);

  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  // Filter routes based on role_id condition
  const filteredRoutes = routes.filter((route) => {
    // Remove routes based on the user role
    if (
      (userInfo?.data?.role_id === ROLE_EMPLOYEE ||
        userInfo?.data?.role_id === ROLE_USER) &&
      (route.path === "user-list" ||
        route.path === "add-user" ||
        route.path === "employee-list" ||
        route.path === "department")
    ) {
      return false; // Exclude these routes
    } else if (
      (userInfo?.data?.role_id === ROLE_ADMIN ||
        userInfo?.data?.role_id === ROLE_SUPERADMIN) &&
      route.path === ""
      // route.path === "assign-enquiry-list"
    ) {
      return false; // Exclude these routes
    } else {
      return true; // Include other routes
    }
  });

  // The function that maps over the routes
  const getRoutes = (filteredRoutes) => {
    return filteredRoutes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.children) {
          // If the route has children, map over the child routes
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key}>
              {prop.children.map((child, childKey) => (
                <Route
                  path={child.path}
                  element={child.component}
                  key={`${key}-${childKey}`}
                >
                  {/* Handle inner children if they exist */}
                  {child.InnerChildren &&
                    child.InnerChildren.map((innerChild, innerChildKey) => (
                      <Route
                        path={innerChild.path}
                        element={innerChild.component}
                        key={`${childKey}-${innerChildKey}`}
                      />
                    ))}
                </Route>
              ))}
            </Route>
          );
        } else {
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
          );
        }
      }
      return null;
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="hide-scroll-bar h-full w-full overflow-scroll bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Kataria travels "}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="mx-auto mb-auto h-full min-h-[84vh] p-0 pt-0 md:pr-2 md:pt-5">
              <Routes>
                {getRoutes(filteredRoutes)}

                {/* <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                /> */}
                <Route
                  path="*"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">{/* <Footer /> */}</div>
          </div>
        </main>
      </div>
    </div>
  );
}
