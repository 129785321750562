import React, { useEffect, useState, useMemo } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Lock, Person } from "@mui/icons-material";
import {
  FaMailBulk,
  FaUser,
  FaUsers,
  FaUserSecret,
  FaUserShield,
  FaUserTie,
  FaWhatsapp,
} from "react-icons/fa";
import userResources from "api/userResources";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import Select from "react-tailwindcss-select";
import Crossicon from "components/CrossIcon/Crossicon";
import { selectUserInfo } from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import SubmitButton from "components/SubmitButton/SubmitButton";

export default function UserList() {
  const userInfo = useSelector(selectUserInfo);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [options, setOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [roleId, setRoleId] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getUserList = async (role_id) => {
    const params = {
      role_id: roleId || role_id,
      page: pagination.pageIndex, // Adjusting to match `page` in `userList`
      size: pagination.pageSize, // Adjusting to match `size` in `userList`
      globalFilter,
    };
    setIsLoading(true);

    try {
      const response = await userResources.userList(params);
      setData(response?.data?.data || []);
      setTotalRows(response.data.paginate_data.total || 0);
    } catch (error) {
      console.error("Error fetching User's list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const loadingToastId = toast.loading("Deleting...");
    try {
      await userResources.userDelete(id, userInfo.data.id);
      toast.update(loadingToastId, {
        render: "Successfully deleted.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      getUserList(roleId);
      setRoleId(null);
    } catch (error) {
      toast.update(loadingToastId, {
        render: error.response?.data?.message || "Failed to delete entry.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const toggleStatus = async (id) => {
    const loadingToastId = toast.loading("Processing...");
    try {
      const res = await userResources.userVerify(id);
      getUserList();
      setRoleId(null);
      toast.update(loadingToastId, {
        render: res.data.message || "Status updated successfully.",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(loadingToastId, {
        render: error.message || "Error updating status.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserProfile({}); // Reset user profile on close
  };

  const columns = useMemo(
    () => [
      { accessorKey: "id", header: "ID" },
      { accessorKey: "name", header: "Name" },
      { accessorKey: "email", header: "Email" },
      { accessorKey: "phone_no", header: "Phone Number" },
      {
        accessorKey: "role_id",
        header: "Role",
        Cell: ({ cell }) => {
          const roleMap = {
            [process.env.REACT_APP_ROLE_ADMIN]: "Admin",
            [process.env.REACT_APP_ROLE_EMPLOYEE]: "Employee",
            [process.env.REACT_APP_ROLE_SUPERADMIN]: "Super Admin",
            [process.env.REACT_APP_ROLE_USER]: "User",
          };
          return roleMap[cell.getValue()] || "N/A";
        },
      },
      {
        accessorKey: "branch",
        header: "Branch",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "department",
        header: "Department",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "created_at",
        header: "Created at",
        Cell: ({ cell }) =>
          new Date(cell.getValue()).toISOString().split("T")[0],
      },
      {
        accessorKey: "age",
        header: "Age",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "salary",
        header: "Salary",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            className="toggle toggle-success"
            checked={row.original.status === "active"}
            onChange={() => toggleStatus(row.original.id)}
            style={{ cursor: "pointer" }}
          />
        ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Edit
              className="cursor-pointer text-blue-500"
              // onClick={handleEdit}
            />
            {/* <Lock className="cursor-pointer text-yellow-500" /> */}
            <Person
              className="cursor-pointer text-green-500"
              onClick={() => handleOpenModal(row.original)} // Wrap in an arrow function
            />
            <ConfirmModal
              onConfirm={handleDelete}
              title="Delete Profile"
              message="Are you sure you want to delete this Profile?"
              employeeId={row.original.id}
            />
          </div>
        ),
      },
    ],
    []
  );

  const sendEmailToAll = () => {
    const emails = data.map((user) => user.email).join(",");
    window.open(`mailto:${emails}?subject=Hello Everyone!&body=Hi Team,%0A%0A`);
  };

  const handleGlobalFilterChange = (value) => setGlobalFilter(value);

  useEffect(() => {
    getUserList();
  }, [pagination, globalFilter, roleId]);

  const handlePaginationChange = (updatedPagination) =>
    setPagination(updatedPagination);

  const getRoleList = async () => {
    try {
      const response = await userResources.role();
      const icons = [FaUser, FaUserTie, FaUsers, FaUserShield, FaUserSecret];

      const formattedRoles = response.data.data.map((role) => ({
        value: role.id,
        label: role.name,
        icon: icons[Math.floor(Math.random() * icons.length)],
      }));

      setOptions(formattedRoles);
    } catch (error) {
      console.log("Failed to fetch roles:", error);
    }
  };

  useEffect(() => {
    getRoleList();
  }, []);
  const handleChangeRole = (selectedOption) => {
    setRoleId(selectedOption);
  };

  return (
    <section className="mx-2 mt-0 sm:mt-5">
      <ScrollToTopOnRouteChange />
      <div className="mb-4 grid grid-cols-1 items-end sm:grid-cols-2 sm:gap-4">
        <SubmitButton
          onClick={sendEmailToAll}
          add="Send Email to All"
          extraClass="bg-green-600 px-4 h-12 py-2 text-white hover:bg-green-700 rounded-[20px] mt-2 sm:mt-0"
        />
        <div className=" items-center ">
          <div className="relative  w-full ">
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Role
            </label>
            <Select
              value={roleId}
              onChange={handleChangeRole}
              options={options}
              formatOptionLabel={({ label }, meta = {}) => {
                const { isSelected } = meta;

                const Icon =
                  label === "Employee"
                    ? FaUserTie
                    : label === "Admin"
                    ? FaUserShield
                    : FaUser;

                return (
                  <div
                    className={`block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      isSelected
                        ? `bg-gray-900 text-white`
                        : `text-gray-900 hover:bg-gray-900 hover:text-white`
                    }`}
                  >
                    <div className="flex items-center">
                      {Icon && <Icon className="mr-2" />}
                      <span>{label}</span>
                    </div>
                  </div>
                );
              }}
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex text-sm w-full cursor-pointer text-gray-900 dark:text-white justify-between px-2 dark:bg-transparent border border-gray-300 dark:!border-white/10 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                    isDisabled ? "bg-gray-200" : "bg-white "
                  }`,
                menu: "absolute top-10 z-10 w-full px-0 pl-0 bg-white shadow-lg border rounded py-1 mt-1.5 border-gray-300",
              }}
            />
            <div
              className="absolute -right-2 top-4"
              onClick={() => setRoleId(null)}
            >
              <Crossicon />
            </div>
          </div>
        </div>
      </div>
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ pagination, isLoading }}
        enablePagination
        onPaginationChange={handlePaginationChange}
        manualPagination
        rowCount={totalRows}
        pageCount={Math.ceil(totalRows / pagination.pageSize)}
        enableGlobalFilter
        onGlobalFilterChange={handleGlobalFilterChange}
        globalFilter={globalFilter}
        enableRowActions
        renderRowActions={({ row }) => (
          <div className="flex flex-nowrap gap-4">
            <button
              className="text-xl text-primary"
              onClick={() =>
                window.open(
                  `mailto:${row.original.email}?subject=Hello ${row.original.name}!` // Ensure correct field name
                )
              }
            >
              <FaMailBulk />
            </button>
            <button
              className="text-xl text-success"
              onClick={
                () =>
                  window.open(
                    `https://wa.me/${row.original.phone_no.replace(
                      /\D/g,
                      ""
                    )}?text=Hello ${row.original.name}!`
                  ) // Ensure correct field name
              }
            >
              <FaWhatsapp />
            </button>
          </div>
        )}
      />

      {/* Modal for User Info */}
      <div className="">
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>
    </section>
  );
}
