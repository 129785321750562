import React, { useEffect, useState } from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import Departments from "views/admin/CRM/Departments";
import userResources from "api/userResources";
import { avtar } from "utils/images";
import { IoAddCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo, setUserInfo } from "../../../../redux/userSlice";
import { toast } from "react-toastify";

const Banner = ({ data }) => {
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [uplaodImage, setUplaodImage] = useState();
  console.log(userInfo?.data, "userInfo?.data?.id");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result); // Set the selected image preview
      };
      reader.readAsDataURL(file);
      setUplaodImage(file);
    }
  };

  const getDepartment = async () => {
    try {
      const response = await userResources.departments(); // Replace with your API endpoint
      setDepartmentData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };

  const updateProfile = async (image) => {
    const formData = new FormData();
    formData.append("id", userInfo?.data?.id);
    formData.append("role_id", userInfo?.data?.role_id);
    formData.append("image", image); // Append the file to the FormData object

    const toastId = toast.loading("Updating profile...");
    const token = userInfo?.data?.plain_text_token;

    if (!token) {
      toast.update(toastId, {
        render: "No token found. Please log in again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      const response = await userResources.updateprofile(formData, token);

      console.log(response, "response");
      const updatedUserInfo = {};
      if (response.data.status) {
        // Merge existing token with updated user info
        updatedUserInfo.data = {
          ...response.data.data, // New user info from the API
          plain_text_token: token, // Preserve the existing token
          // role_id: Number(response.data.data.role_id), // Convert user_id to number
        };
        console.log(updatedUserInfo, "updatedUserInfo");
        console.log(userInfo?.data, "before");

        // Update the Redux store
        dispatch(setUserInfo({ ...userInfo, ...updatedUserInfo }));
        console.log(userInfo?.data, "after");

        toast.update(toastId, {
          render: "Profile updated successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render:
          error?.response?.data?.message ||
          "Failed to update profile. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  useEffect(() => {
    setSelectedImage(null);
  }, [userInfo]);

  useEffect(() => {
    if (data && departmentData.length > 0) {
      const department = departmentData.find(
        (dept) => dept.id === data?.department_id
      );
      setDepartmentName(department ? department.name : null);
    }
  }, [data, departmentData]);

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center  rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <div className="relative">
            <div className="flex h-[87px] w-[87px] items-center justify-center overflow-hidden rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
              <img
                className="h-full w-full  rounded-full"
                src={selectedImage || data?.full_path || avtar}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avtar; // Default avatar
                }}
                alt="Selected"
              />
            </div>
            <div className="absolute bottom-0 right-0 z-10">
              {/* Hidden file input */}
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label htmlFor="fileInput">
                <IoAddCircle
                  className="cursor-pointer text-blue-500"
                  size={22}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {data?.name}
        </h4>
        {departmentName ? (
          <p className="text-base font-normal text-gray-600">
            {departmentName}
          </p>
        ) : (
          <p className="text-base font-normal text-gray-600">{data?.email}</p>
        )}
      </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
        {data?.salary && data?.age && (
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-navy-700 dark:text-white">
              Email
            </p>
            <p className="text-sm font-normal text-gray-600">{data?.email}</p>
          </div>
        )}
        {data?.age && (
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-navy-700 dark:text-white">
              Age
            </p>
            <p className="text-sm font-normal text-gray-600">{data?.age}</p>
          </div>
        )}
        {data?.salary && (
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-navy-700 dark:text-white">
              Salary
            </p>
            <p className="text-sm font-normal text-gray-600">{data?.salary}</p>
          </div>
        )}
      </div>
      {selectedImage && (
        <div className="absolute bottom-2 right-5 flex items-center gap-2">
          <p
            className="cursor-pointer text-red-500 hover:text-red-700"
            onClick={() => setSelectedImage(null)}
          >
            Cancel
          </p>
          <p
            className="cursor-pointer text-green-500 hover:text-green-700"
            onClick={() => updateProfile(uplaodImage)}
          >
            Save
          </p>
        </div>
      )}
    </Card>
  );
};

export default Banner;
