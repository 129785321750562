import React, { useEffect } from "react";

function DisableScrollForNumberInputs() {
  useEffect(() => {
    // Select all number input fields
    const numberInputs = document.querySelectorAll('input[type="number"]');
    // Disable scroll behavior
    numberInputs.forEach((input) => {
      input.addEventListener("wheel", (event) => {
        event.preventDefault();
      });
    });

    // Cleanup on component unmount
    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener("wheel", (event) => {
          event.preventDefault();
        });
      });
    };
  }, []);

  return null;
}

export default DisableScrollForNumberInputs;
