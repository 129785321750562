import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import userResources from "api/userResources";
import { useEffect, useState } from "react";
import { IoMdAirplane } from "react-icons/io";
import { FaHotel, FaMapMarkedAlt } from "react-icons/fa";
import { motion } from "framer-motion";
const Dashboard = ({
  ProfileData,
  ProfileModalData,
  profileModalClassName,
  assignListNavigate,
  createdListNavigate,
  hotelprofileData,
  hotelassignListNavigate,
  hotelcreatedListNavigate,
}) => {
  // animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Delay between child animations
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div>
      {/* Card widget */}

      <motion.div
        className={`mt-3 ${
          profileModalClassName
            ? "grid-col-1 grid gap-y-2 p-3"
            : "grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3"
        }`}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants}>
          <Widget
            heading={"Flight"}
            icon={
              <IoMdAirplane className="h-7 w-7 text-purple-500 dark:text-white" />
            }
            // title={"Total Enquiries"}
            // subtitle={`${data?.totalEnquiries || 0}`}
            cardbgColor={"bg-purple-500 cursor-pointer"}
            titleColor={"text-gray-900 dark:text-white"}
            navigateTo={"/admin/flight-statistics"}
            assignListNavigate={assignListNavigate}
            createdListNavigate={createdListNavigate}
            data={ProfileData ? ProfileData : ProfileModalData}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Widget
            heading={"Hotel"}
            icon={
              <FaHotel className="h-7 w-7 text-green-500 dark:text-white" />
            }
            // title={"Total Enquiries"}
            // subtitle={`${data?.totalEnquiries || 0}`}
            cardbgColor={"bg-green-500 cursor-pointer"}
            titleColor={"text-gray-900 dark:text-white"}
            navigateTo={"/admin/hotel-statistics"}
            assignListNavigate={hotelassignListNavigate}
            createdListNavigate={hotelcreatedListNavigate}
            data={hotelprofileData ? hotelprofileData : ProfileModalData}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Widget
            heading={"Packages"}
            icon={
              <FaMapMarkedAlt className="h-7 w-7 text-yellow-500 dark:text-white" />
            }
            // title={"Total Enquiries"}
            // subtitle={`${data?.totalEnquiries || 0}`}
            cardbgColor={"bg-yellow-500 cursor-pointer"}
            titleColor={"text-gray-900 dark:text-white"}
            navigateTo={"/admin/packages-statistics"}
            assignListNavigate={assignListNavigate}
            data={ProfileData}
          />
        </motion.div>
      </motion.div>

      {/* Charts */}
      {/* 
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
