import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import userResources from "api/userResources";

export default function NavbarSearchAnyEnquirys() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = async (type) => {
    if (!searchTerm.trim()) {
      toast.warning("Please enter client phone number.");
      return;
    }

    try {
      if (type === "Flight") {
        const response = await userResources.searchEnquiryList({
          phone_number: searchTerm,
        });

        if (response?.data?.status) {
          toast.success(response.data.message || "Search successful!");
        } else {
          toast.warning(response.data.message || "No matching results found.");
        }
        if (response?.data?.data?.length > 0) {
          navigate("/admin/search-enquiry", { state: response?.data?.data });
        }
        console.log("API Response:", response?.data?.data);
      } else if (type === "Hotel") {
        // Call different API for hotel
        const hotelResponse = await userResources.searchHotelEnquiryList({
          phone_number: searchTerm,
        });

        if (hotelResponse?.data?.status) {
          toast.success(
            hotelResponse.data.message || "Hotel search successful!"
          );
        } else {
          toast.warning(
            hotelResponse.data.message || "No matching hotel enquiries found."
          );
        }
        if (hotelResponse?.data?.data?.length > 0) {
          navigate("/admin/search-hotel-enquirys", {
            state: hotelResponse?.data?.data,
          });
        }
        console.log("Hotel API Response:", hotelResponse?.data?.data);
      }
      setSearchTerm(""); // Reset the search term
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to fetch search results."
      );
      console.error("API Error:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!searchTerm.trim()) {
        // Show warning if input is empty
        toast.warning("Please enter a phone number to search.");
        return;
      }

      // Show a toast to prompt user to select the enquiry type
      const toastId = toast.info(
        <div className="text-left">
          <p>Select the type of enquiry:</p>
          <div className="justify-left mt-2 flex items-center gap-4">
            <button
              className="btn btn-primary text-white"
              onClick={() => {
                handleSearch("Flight");
                toast.dismiss(toastId); // Close the toast after selection
              }}
            >
              Flight
            </button>
            <button
              className="btn btn-secondary text-white"
              onClick={() => {
                handleSearch("Hotel");
                toast.dismiss(toastId); // Close the toast after selection
              }}
            >
              Hotel
            </button>
          </div>
        </div>,
        {
          position: "top-center",
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <>
      {/* Search Bar */}
      <section className="flex h-full w-full items-center overflow-hidden rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="number"
          placeholder="Search enquiry by Number..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </section>
    </>
  );
}
