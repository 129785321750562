import React from "react";
import HotelList from "./HotelList";

export default function HotelAssignEnquiryList() {
  return (
    <section>
      <HotelList api="hotelassignenquirylist" />
    </section>
  );
}
