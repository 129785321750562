import Card from "components/card";
import { useNavigate } from "react-router-dom";

const Widget = ({
  cardClasses,
  imagePadding,
  icon,
  title,
  subtitle,
  cardbgColor,
  titleColor,
  heading,
  navigateTo,
  data,
  name,
  id,
  thisSearchFor,
  assignListNavigate,
  createdListNavigate,
  filterWithEmployee,
}) => {
  return (
    <Card
      cardClasses={cardClasses}
      extra={`!flex-row flex-grow items-center rounded-[20px] ${cardbgColor}`}
      navigateTo={navigateTo}
      data={data}
      name={name}
      id={id}
      thisSearchFor={thisSearchFor}
      assignListNavigate={assignListNavigate}
      createdListNavigate={createdListNavigate}
      filterWithEmployee={filterWithEmployee}
    >
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div
          className={`rounded-full bg-lightPrimary ${
            imagePadding ? imagePadding : "p-3"
          } dark:bg-navy-700`}
        >
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        {heading && (
          <p
            className={`text-md font-dm font-semibold ${
              titleColor ? titleColor : "text-gray-600"
            }`}
          >
            {heading}
          </p>
        )}
        <p
          className={`text-md font-dm font-medium  ${
            titleColor ? titleColor : "text-gray-600"
          }`}
        >
          {title} {title ? ":" : ""} {subtitle}
        </p>
        {/* <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4> */}
      </div>
    </Card>
  );
};

export default Widget;
