import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaPlane } from "react-icons/fa"; // Importing the plane icon for airlines
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { selectUserInfo } from "../../../redux/userSlice";
import { useSelector } from "react-redux";

export default function Airlines() {
  const userInfo = useSelector(selectUserInfo);
  const [airlinesData, setAirlinesData] = useState([]);
  const [editingAirline, setEditingAirline] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    airline_name: "",
    airline_code: "",
    country: "",
    price: "",
  });
  const [errors, setErrors] = useState({});

  const getairLines = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    try {
      const response = await userResources.airlines(
        pageIndex || 0,
        pageSize || 5,
        globalFilter || ""
      );
      setAirlinesData(response.data.data);
      setTotalRows(response.data.paginate_data.total); // Set total rows for pagination
    } catch (error) {
      console.error("Error fetching airline data:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };

  const validateField = (name, value) => {
    // Field validation logic
    let errorMessage = "";
    if (name === "airline_name" && !value) {
      errorMessage = "Airline name is required.";
    } else if (name === "airline_code" && !value) {
      errorMessage = "Airline code is required.";
    } else if (name === "country" && !value) {
      errorMessage = "Country is required.";
    } else if (name === "price" && !value) {
      errorMessage = "Price is required.";
    }

    return errorMessage;
  };

  const handleChange = (name, value) => {
    const fieldValue = value.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));

    // Validate the field and set error message if needed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, fieldValue),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields on form submission
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const errorMessage = validateField(key, formData[key]);
      if (errorMessage) {
        newErrors[key] = errorMessage;
      }
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the errors before submitting.");
      return; // If there are errors, do not proceed
    }

    // Show loading toast
    setDisabled(true);
    const loadingToastId = toast.loading("Submitting...");

    try {
      if (editingAirline) {
        // Update existing airline
        await userResources.addAirlines({
          id: editingAirline.id,
          ...formData,
        });
        setEditingAirline(null);
      } else {
        // Add new airline
        await userResources.addAirlines({
          ...formData,
        });
      }

      // Refresh the airline list
      getairLines();

      // Update toast to success
      toast.update(loadingToastId, {
        render: editingAirline
          ? "Airline updated successfully."
          : "Airline added successfully.",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      // Reset form data after successful submission
      setFormData({
        airline_name: "",
        airline_code: "",
        country: "",
        price: "",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to submit form. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      // Handle individual field errors if needed
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      // Ensure disabled state is reset regardless of success or error
      setDisabled(false);
    }
  };

  const airlinesColumns = [
    { accessorKey: "airline_name", header: "Airline" },
    { accessorKey: "country", header: "Country" },
    { accessorKey: "airline_code", header: "Airline Code" },
    { accessorKey: "price", header: "Price" },
    {
      accessorKey: "created_at",
      header: "Created at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "updated_at",
      header: "Updated at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <Edit
            className="cursor-pointer text-blue-500"
            onClick={() => handleEdit(row.original)}
            aria-label={`Edit ${row.original.name}`}
          />
          <div>
            {userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_EMPLOYEE) ? null : (
              <ConfirmModal
                onConfirm={handleDelete}
                title="Delete Airline"
                message="Are you sure you want to delete this airline?"
                employeeId={row.original.id}
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleEdit = (airline) => {
    setFormData({
      airline_name: airline.airline_name,
      airline_code: airline.airline_code,
      country: airline.country,
      price: airline.price,
    });
    setEditingAirline(airline);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setEditingAirline(null);
    setFormData({ airline_name: "", airline_code: "", country: "", price: "" });
    setErrors({});
  };

  const handleDelete = async (id) => {
    // Show loading toast
    const loadingToastId = toast.loading("Deleting airline...");

    try {
      await userResources.airlinesDelete(id); // Attempt to delete the airline

      // Update toast to show success
      toast.update(loadingToastId, {
        render: "Airline deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Refresh the airline list
      getairLines(pagination.pageIndex, pagination.pageSize, globalFilter);
    } catch (error) {
      console.error("Error deleting airline:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to delete airline. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      // Dismiss the loading toast if not already dismissed
      if (toast.isActive(loadingToastId)) {
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };

  // Calculate total pages
  const totalPages = Math.ceil(airlinesData.length / pagination.pageSize);

  // Navigate to first page
  const goToFirstPage = () => {
    setPagination({ ...pagination, pageIndex: 0 });
  };

  // Navigate to last page
  const goToLastPage = () => {
    setPagination({ ...pagination, pageIndex: totalPages - 1 });
  };

  useEffect(() => {
    getairLines();
  }, []);

  useEffect(() => {
    getairLines(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  return (
    <section className="mt-6">
      <ScrollToTopOnRouteChange />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        {/* Add Airline Form */}
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            {editingAirline ? "Edit Airline" : "Add Airline"}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-2">
              <InputField
                label="Airline Name"
                name="airline_name"
                type="text"
                placeholder="Airline Name"
                value={formData.airline_name}
                onChange={(value) => handleChange("airline_name", value)}
                state={errors.airline_name ? "error" : ""}
                errorMessage={errors.airline_name}
              />

              <InputField
                label="Airline Code"
                name="airline_code"
                type="text"
                placeholder="Airline Code"
                value={formData.airline_code}
                onChange={(value) => handleChange("airline_code", value)}
                state={errors.airline_code ? "error" : ""}
                errorMessage={errors.airline_code}
              />
              <InputField
                label="Country"
                name="country"
                type="text"
                placeholder="Country"
                value={formData.country}
                onChange={(value) => handleChange("country", value)}
                state={errors.country ? "error" : ""}
                errorMessage={errors.country}
              />
              <InputField
                label="Price"
                name="price"
                type="number"
                placeholder="Price"
                value={formData.price}
                onChange={(value) => handleChange("price", value)}
                state={errors.price ? "error" : ""}
                errorMessage={errors.price}
              />
            </div>
            <div className="mt-6 flex space-x-2">
              {editingAirline && (
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-full rounded-lg border border-red-500 p-2 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
                >
                  Cancel
                </button>
              )}
              <SubmitButton
                editing={editingAirline}
                disabled={disabled}
                add="Add Airline"
                edit="Update Airline"
              />
            </div>
          </form>
        </div>

        {/* Airlines Table */}
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            Airlines List
          </h3>
          <MaterialReactTable
            columns={airlinesColumns}
            data={airlinesData}
            state={{ pagination, isLoading }} // Use both states here
            // state={{ pagination, isLoading, showProgressBars: isLoading }} // Use both states here
            enablePagination
            onPaginationChange={handlePaginationChange}
            manualPagination
            rowCount={totalRows}
            pageCount={Math.ceil(totalRows / pagination.pageSize)}
            isLoading={isLoading}
            enableColumnFilters={false}
            enableSorting={false}
            enableColumnOrdering={false}
            muiTablePaginationProps={{
              showFirstButton: true,
              showLastButton: true,
            }}
            enableGlobalFilter // Keep this true to allow global filtering
            onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
            globalFilter={globalFilter} // Pass the state variable to the table
          />
        </div>
      </div>
    </section>
  );
}
