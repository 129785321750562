import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import {
  MdAirplanemodeActive,
  MdAssignment,
  MdAssignmentInd,
  MdBusiness,
  MdLock,
  MdPeopleOutline,
  MdQuestionAnswer,
} from "react-icons/md";
import { logout } from "../../redux/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RoutesDropdown from "components/RoutesDropdown/RoutesDropdown";
import { useState } from "react";
import {
  FaCodeBranch,
  FaEnvelope,
  FaHotel,
  FaPlane,
  FaUsers,
} from "react-icons/fa";
import { RiCustomerService2Line } from "react-icons/ri";
import katariatravels from "utils/images";
import { toast } from "react-toastify";
import userResources from "api/userResources";
import Logout from "components/Logout/Logout";
import { selectUserInfo, setUserInfo, login } from "../../redux/userSlice";

const Sidebar = ({ open, onClose }) => {
  const [dropDown, setDropdown] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  console.log(userInfo, "userInfo.role_id");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const currentRoute = params["*"]; // This will capture the current route path

  // Filter routes to only include those with a name key
  const filteredRoutes = routes.filter((route) => route.show);

  // Function to check if the current route matches any dropdown option value or config value
  const isActiveRoute = (optionValue) => {
    // Check if the optionValue is an array and if currentRoute matches any value in it
    if (Array.isArray(optionValue)) {
      return optionValue.includes(currentRoute);
    }
    // Otherwise, check for a direct match
    return currentRoute === optionValue;
  };

  // Updated function to check if CRM is active
  const isCRMActive = () => {
    return dropdownConfigs.some(
      (config) =>
        // Check against options
        config.options?.some((option) => isActiveRoute(option.value)) ||
        // Check against the config value
        isActiveRoute(config.value)
    );
  };

  // Dropdown configuration array
  const dropdownConfigs = [
    {
      name: "User Management",
      icon: <FaUsers className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />,
      options: [
        { value: "add-user", label: "Add User", route: "/auth/add-user" },
        { value: "user-list", label: "User's List", route: "/admin/user-list" },
        {
          value: "employee-list",
          label: "Employee List",
          route: "/admin/employee-list",
        },
        {
          value: "branch",
          label: "Branch",
          route: "/admin/branch",
        },
        {
          value: "department",
          label: "Department",
          route: "/admin/department",
        },
      ],
    },
    {
      value: [
        "create-enquiry",
        "create-enquiry/flight/round-trip",
        "create-enquiry/flight/oneway",
        "create-enquiry/flight/multi-city",
      ],
      icon: (
        <MdQuestionAnswer className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
      ),
      name: "Create New Enquiry",
      route: "/admin/create-enquiry/flight/round-trip",
    },
    {
      name: "Flight Enquirys",
      icon: <FaPlane className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />,
      options: [
        {
          value: "enquiry-list",
          label: "All Enquiry List",
          route: "/admin/enquiry-list",
        },
        {
          value: "created-enquiry-list",
          label: "Created Enquiry List",
          route: "/admin/created-enquiry-list",
        },
        {
          value: "assigned-enquiry-list",
          label: "Assigned Enquiry List",
          route: "/admin/assigned-enquiry-list",
        },
        {
          value: "search-enquiry",
          label: "Search Enquiry",
          route: "/admin/search-enquiry",
        },
        {
          value: "enquiry-source",
          label: "Enquiry Source",
          route: "/admin/enquiry-source ",
        },
        {
          value: "enquiry-status",
          label: "Enquiry Status",
          route: "/admin/enquiry-status",
        },

        {
          label: "Air Lines",
          route: "/admin/air-lines",
          value: "air-lines",
        },
      ],
    },

    {
      name: "Hotel Enquirys",
      icon: <FaHotel className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />,
      options: [
        {
          value: "hotel-enquirys",
          label: "All Enquiry List",
          route: "/admin/hotel-enquirys",
        },
        {
          value: "hotel-created-enquirys",
          label: "Created Enquirys List",
          route: "/admin/hotel-created-enquirys",
        },
        {
          value: "hotel-assigned-enquirys",
          label: "Assigned Enquirys List",
          route: "/admin/hotel-assigned-enquirys",
        },
        {
          value: "search-hotel-enquirys",
          label: "Search Enquirys",
          route: "/admin/search-hotel-enquirys",
        },
      ],
    },
    {
      name: "Invoice",
      icon: (
        <FaCodeBranch className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
      ),
      options: [
        {
          value: "create-invoice",
          label: "Create Invoice",
          route: "/admin/create-invoice",
        },
        {
          value: "invoice-list",
          label: "Invoice List",
          route: "/admin/invoice-list",
        },
      ],
    },
  ];

  // Get role IDs from environment variables
  const ROLE_EMPLOYEE = Number(process.env.REACT_APP_ROLE_EMPLOYEE);
  const ROLE_USER = Number(process.env.REACT_APP_ROLE_USER);
  const ROLE_ADMIN = Number(process.env.REACT_APP_ROLE_ADMIN);
  const ROLE_SUPERADMIN = Number(process.env.REACT_APP_ROLE_SUPERADMIN);

  // Initialize an empty array for the filtered configs
  const filteredDropdownConfigs = [];

  // Use a loop with if-else to filter dropdownConfigs
  dropdownConfigs.forEach((config) => {
    // Exclude "User Management" and "Department" for ROLE_EMPLOYEE and ROLE_USER
    if (
      (userInfo.data.role_id === ROLE_EMPLOYEE ||
        userInfo.data.role_id === ROLE_USER) &&
      (config.name === "User Management" || config.name === "Department")
    ) {
      // Skip these configs for ROLE_EMPLOYEE and ROLE_USER
      return;
    }

    // For ROLE_ADMIN and ROLE_SUPERADMIN, filter out options based on value
    if (
      userInfo.data.role_id === ROLE_ADMIN ||
      userInfo.data.role_id === ROLE_SUPERADMIN
    ) {
      // Remove all options where option.value === option.value (effectively removes all options)
      const filteredOptions = config.options?.filter(
        (option) => option.value !== ""
        // (option) => option.value !== "assign-enquiry-list"
      );

      // If there are no options left after filtering, skip this config entirely
      if (filteredOptions?.length === 0) return;

      // Add the config with filtered options for ROLE_ADMIN and ROLE_SUPERADMIN
      filteredDropdownConfigs.push({ ...config, options: filteredOptions });
    } else {
      // For other roles, include the config as is
      filteredDropdownConfigs.push(config);
    }
  });

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[100px] mt-[10px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-[#dea113]">
          {/* Kataria <span className="font-medium">Travels</span> */}
          <img src={katariatravels} alt="" className="w-24" />
        </div>
      </div>
      <div className="relative z-10 mb-7 mt-[100px] h-px overflow-scroll bg-gray-300 dark:bg-white/30" />

      <ul className="hide-scroll-bar mb-auto max-h-[500px] overflow-y-auto pt-1">
        <Links routes={filteredRoutes} />
        {/* CRM */}
        <li className="mx-8 my-4">
          <div
            className="flex cursor-pointer items-center"
            onClick={() => setDropdown(!dropDown)}
          >
            <RiCustomerService2Line className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
            <p
              className={`font-semibold text-gray-500 hover:text-gray-900 dark:hover:text-white ${
                isCRMActive() ? "text-gray-900 dark:text-white" : ""
              }`}
            >
              CRM
            </p>
          </div>

          {/* Render dropdowns dynamically */}
          {dropDown && (
            <ul>
              {filteredDropdownConfigs.map((config, index) => (
                <li key={index} className="my-4 flex cursor-pointer">
                  {config.icon}
                  {/* Wrap inner content in a <div> or other element */}
                  <div>
                    {config.route ? (
                      <div
                        onClick={() => navigate(config.route)}
                        className={`font-semibold hover:text-black dark:hover:text-white ${
                          isActiveRoute(config.value)
                            ? "text-gray-900 dark:text-white"
                            : "text-gray-500"
                        }`}
                      >
                        {config.name}
                      </div>
                    ) : (
                      <RoutesDropdown
                        options={config.options}
                        name={config.name}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>

      <Logout />

      <div className="flex justify-center">{/* <SidebarCard /> */}</div>
    </div>
  );
};

export default Sidebar;
