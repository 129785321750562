import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaPlane } from "react-icons/fa"; // Importing the plane icon for departments
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton/SubmitButton";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { selectUserInfo } from "../../../../redux/userSlice";
import { useSelector } from "react-redux";

export default function InvoiceList() {
  const userInfo = useSelector(selectUserInfo);
  const [globalFilter, setGlobalFilter] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getInvoice = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    try {
      const response = await userResources.getinvoiceData(
        pageIndex || 0,
        pageSize || 5,
        globalFilter || ""
      );
      setInvoiceData(response.data.data);
      setTotalRows(response.data.paginate_data.total); // Set total rows for pagination
    } catch (error) {
      console.error("Error fetching Invoice data:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };

  const invoiceColumns = [
    { accessorKey: "invoice_number", header: "Invoice Number" },
    { accessorKey: "agent_name", header: "Agent Name" },
    { accessorKey: "airLine_id", header: "Airline ID" },
    { accessorKey: "amex_card", header: "Amex Card" },
    { accessorKey: "bmo_master_card", header: "BMO Master Card" },
    { accessorKey: "ch_eq_ue", header: "Cheque" },
    { accessorKey: "cibc_card", header: "CIBC Card" },
    { accessorKey: "cost", header: "Cost" },
    { accessorKey: "date", header: "Date" },
    { accessorKey: "fnu", header: "FNU" },
    { accessorKey: "mode_of_payment", header: "Mode of Payment" },
    { accessorKey: "pnr", header: "PNR" },
    { accessorKey: "rajni_mam", header: "Rajni Mam" },
    { accessorKey: "reference_number_of_et", header: "Reference Number of ET" },
    { accessorKey: "remarks", header: "Remarks" },
    { accessorKey: "sold_fare", header: "Sold Fare" },
    { accessorKey: "supplier", header: "Supplier" },
    { accessorKey: "td_busness_visa_card", header: "TD Business Visa Card" },
    { accessorKey: "td_fc_visa", header: "TD FC Visa" },
    { accessorKey: "ticket_number", header: "Ticket Number" },
    { accessorKey: "ticket_status", header: "Ticket Status" },
    {
      accessorKey: "created_at",
      header: "Created At",
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return <span>{date.toISOString().split("T")[0]}</span>;
      },
    },
    {
      accessorKey: "updated_at",
      header: "Updated At",
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return <span>{date.toISOString().split("T")[0]}</span>;
      },
    },
  ];

  // Conditionally add the "Action" column if the user is a super admin
  if (
    userInfo?.data?.role_id === Number(process.env.REACT_APP_ROLE_SUPERADMIN)
  ) {
    invoiceColumns.push({
      accessorKey: "action",
      header: "Action",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <Edit
            className="cursor-pointer text-blue-500"
            // Uncomment and implement the edit handler
            // onClick={() => handleEdit(row.original)}
            aria-label={`Edit ${row.original.name}`}
          />
          <ConfirmModal
            onConfirm={handleDelete}
            title="Delete Invoice"
            message="Are you sure you want to delete this Invoice?"
            employeeId={row.original.id}
          />
        </div>
      ),
    });
  }

  const handleDelete = async (id) => {
    // Show loading toast
    const loadingToastId = toast.loading("Deleting Invoice...");

    try {
      await userResources.sourceDelete(id); // Attempt to delete the Invoice

      // Update toast to show success
      toast.update(loadingToastId, {
        render: "Invoice deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Refresh the Invoice list
      getInvoice(pagination.pageIndex, pagination.pageSize, globalFilter);
    } catch (error) {
      console.error("Error deleting Invoice:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to delete Invoice. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      // Dismiss the loading toast if not already dismissed
      if (toast.isActive(loadingToastId)) {
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };

  // Calculate total pages
  const totalPages = Math.ceil(invoiceData.length / pagination.pageSize);

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    getInvoice(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  return (
    <section className="mt-6">
      <ScrollToTopOnRouteChange />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        {/* Invoice Table */}

        <MaterialReactTable
          columns={invoiceColumns}
          data={invoiceData}
          state={{ pagination, isLoading }} // Use both states here
          // state={{ pagination, isLoading, showProgressBars: isLoading }} // Use both states here
          enablePagination
          onPaginationChange={handlePaginationChange}
          manualPagination
          rowCount={totalRows}
          pageCount={Math.ceil(totalRows / pagination.pageSize)}
          isLoading={isLoading}
          enableColumnFilters={false}
          enableSorting={false}
          enableColumnOrdering={false}
          muiTablePaginationProps={{
            showFirstButton: true,
            showLastButton: true,
          }}
          enableGlobalFilter // Keep this true to allow global filtering
          onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
          globalFilter={globalFilter} // Pass the state variable to the table
        />
      </div>
    </section>
  );
}
