import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import SubmitButton from "components/SubmitButton/SubmitButton";
import userResources from "api/userResources";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import { avtar } from "utils/images";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import DateFilter from "components/DateFilter/DateFilter";
import SelectFilter from "components/SelectFilter/SelectFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../redux/userSlice";
import { toast } from "react-toastify";
import { paymentStatusDummyDataWithId } from "components/PaymentStatusData/PaymentStatusData";
import { RiMessage2Line } from "react-icons/ri";
import Widget from "components/widget/Widget";
export default function HotelList({ api }) {
  const userInfo = useSelector(selectUserInfo);

  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [title, setTitle] = useState("");
  const [invoice, setInvoice] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [bookingReference, setBookingReference] = useState("");
  const [enquiry_payment_status, setEnquiryPaymentStatus] = useState(null);
  const [enquiryPaymentStausData, setEnquiryPaymentStatusData] = useState([]);
  const [enquiry_source_id, setEnquirySourceId] = useState(null);
  const [enquiryData, setEnquiryData] = useState([]);
  const [enquiryStausData, setEnquiryStatusData] = useState([]);
  const [enquiry_status_id, setEnquiryStatusId] = useState(null);
  const [AssignToData, setAssignToData] = useState([]);
  const [AssignToUserId, setAssignToUserId] = useState(null);
  const [createdByData, setCreatedByData] = useState([]);
  const [createdById, setCreatedById] = useState(null);
  const [updatedById, setUpdatedById] = useState(null);
  const [statusFilter, setStatusFilter] = useState({});
  const [columnsFilterValue, setColumnsFilterValue] = useState([]);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [createdAtFilterDate, setCreatedAtFilterDate] = useState("");
  const [updatedFilterDate, setUpdatedFilterDate] = useState("");
  const [budgetRange, setBudgetRange] = useState({ min: "", max: "" });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  console.log(location?.state, "location");

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserProfile({}); // Reset user profile on close
  };

  const getHotelList = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    setIsError(false); // Reset error state before fetching

    try {
      const params = {
        page: pageIndex + 1 || 1, // Default to page 1 if pageIndex is not provided
        limit: pageSize || 10,
        email: globalFilter || emailFilter || "",
        title: title || null,
        full_name: fullName || null,
        invoice_number: invoice || null,
        phone_number: phone_number || null,
        booking_reference: bookingReference || null,
        check_in_date: checkInDate || null,
        check_out_date: checkOutDate || null,
        enquiry_payment_status:
          location?.state?.thisSearchFor?.name === "enquiryPaymentStatus"
            ? location?.state?.id
            : enquiry_payment_status || null,
        enquiry_source_id:
          location?.state?.thisSearchFor?.name === "enquirySourceStatus"
            ? location?.state?.id
            : enquiry_source_id || null,
        enquiry_status_id: enquiry_status_id || null,
        created_by_user_id: location?.state?.filterWithEmployee
          ? location?.state?.filterWithEmployee?.CreatedUserid
          : createdById || null,
        assigned_to_user_id: location?.state?.filterWithEmployee
          ? location?.state?.filterWithEmployee?.id
          : AssignToUserId || null,
        max_price: budgetRange.max || null || null, // Set max_price if budgetRange.max exists
        min_price: budgetRange.min || null || null, // Set min_price if budgetRange.min exists
        created_at_date: createdAtFilterDate || null,
        updated_at_date: updatedFilterDate || null,
        updated_by_user_id: updatedById || null,
        status: statusFilter || null,
      };

      let response;
      // Dynamic API selection
      if (api === "hotelcreatedenquiryList") {
        response = await userResources.createdHotelEnquiryList(params);
      } else if (api === "hotelassignenquirylist") {
        response = await userResources.hotelEnquiryAssignList(params);
      } else {
        response = await userResources.hotelList(params);
      }

      // Update states with API response
      setTotalRows(response?.data?.paginate_data?.total || 0); // Total rows for pagination
      setData(response?.data?.data || []); // Main data array
    } catch (error) {
      console.error(error); // Log the error
      setIsError(true); // Set error state to true on error
      if (isError) {
        setData([]);
      }
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };
  const formatData = (data, key) =>
    data.map((item) => ({
      id: item?.id,
      name: item?.[key],
    }));

  const handleApiResult = (result, setter, key, label) => {
    if (result.status === "fulfilled") {
      setter(formatData(result.value.data.data, key));
    } else {
      console.error(`Error fetching ${label} data:`, result.reason);
    }
  };

  const getEnquirySource = async () => {
    try {
      const results = await Promise.allSettled([
        userResources.getSources(),
        userResources.enquityStatus(),
        userResources.userList({ role_id: "", size: 9999 }),
      ]);

      const [enquirySourceResult, enquiryStatusResult, allUserResult] = results;

      // Map results to their respective states
      const apiResults = [
        {
          result: enquirySourceResult,
          setter: setEnquiryData,
          key: "name",
          label: "Enquiry Source",
        },
        {
          result: enquiryStatusResult,
          setter: setEnquiryStatusData,
          key: "name",
          label: "Enquiry Status",
        },
        {
          result: allUserResult,
          setter: setAssignToData,
          key: "name",
          label: "User List",
        },
        {
          result: allUserResult,
          setter: setCreatedByData,
          key: "name",
          label: "All User List",
        },
      ];

      setEnquiryPaymentStatusData(paymentStatusDummyDataWithId);
      // Handle each API result
      apiResults.forEach(({ result, setter, key, label }) => {
        handleApiResult(result, setter, key, label);
      });
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  // Utility function to format a date as DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return "Invalid Date"; // Handle invalid date inputs

    // Format date to DD-MM-YYYY
    const formattedDate = dateObj
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");
    return formattedDate;
  };

  // Function to determine text color based on status
  const getStatusTextColor = (status) => {
    switch (status) {
      case "accept":
        return "text-green-700"; // Green for Accepted
      case "pending":
        return "text-gray-400"; // Gray for Pending
      case "reject":
        return "text-red-600"; // Red for Rejected
      default:
        return "text-gray-600"; // Default gray for unknown statuses
    }
  };

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "full_name",
        header: "Customer Name",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "email",
        header: "Email Address",
        enableClickToCopy: true,
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },

      {
        accessorKey: "enquirySource",
        header: "Enquiry Source",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
        filterVariant: "select",
        Filter: ({ column }) => (
          <SelectFilter
            options={enquiryData}
            selectedId={enquiry_source_id}
            setSelectedId={setEnquirySourceId}
            column={column}
            placeholder="Select Enquiry Source"
          />
        ),
      },
      {
        accessorKey: "destination",
        header: "Destination",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
        filterVariant: "select",
      },
      {
        accessorKey: "check_in_date",
        header: "Check In Date",
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={checkInDate}
            setSelectedDate={setCheckInDate}
          />
        ),
        Cell: ({ cell }) => formatDate(cell.getValue()), // Use the utility function
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "check_out_date",
        header: "Check Out Date",
        Cell: ({ cell }) => formatDate(cell.getValue()), // Use the utility function
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={checkOutDate}
            setSelectedDate={setCheckOutDate}
          />
        ),
      },
      {
        header: "Rooms & Guest",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ row }) => {
          const room = row.original.room ?? "N/A";
          const guest = row.original.guest ?? "N/A";

          return (
            <div className="whitespace-nowrap">
              <p>Room: {room}</p>
              <p>Guest: {guest}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "budget",
        header: "Budget",
        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
        filterFn: (row, columnId, filterValue) => {
          const budget = row.getValue(columnId);
          const { min, max } = filterValue || {};
          return (
            (min === "" || budget >= parseFloat(min)) &&
            (max === "" || budget <= parseFloat(max))
          );
        },
        // Add custom filter UI
        Filter: () => (
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="relative">
              <input
                type="number"
                placeholder="Min"
                value={budgetRange.min}
                onChange={(e) => {
                  const value = e.target.value;
                  setBudgetRange((prev) => ({ ...prev, min: value }));
                }}
                style={{ padding: "5px" }}
                className="border-b bg-transparent text-gray-900 outline-none"
              />
              {budgetRange.min && (
                <button
                  onClick={() =>
                    setBudgetRange((prev) => ({ ...prev, min: "" }))
                  }
                  className="absolute right-0 top-1/2 -translate-y-1/2 transform"
                  aria-label="Clear Min Budget"
                >
                  <AiOutlineClose size={16} />
                </button>
              )}
            </div>
            <div className="relative">
              <input
                type="number"
                placeholder="Max"
                value={budgetRange.max}
                onChange={(e) => {
                  const value = e.target.value;
                  setBudgetRange((prev) => ({ ...prev, max: value }));
                }}
                style={{ padding: "5px" }}
                className="border-b bg-transparent text-gray-900 outline-none"
              />
              {budgetRange.max && (
                <button
                  onClick={() =>
                    setBudgetRange((prev) => ({ ...prev, max: "" }))
                  }
                  className="absolute right-0 top-1/2 -translate-y-1/2 transform"
                  aria-label="Clear Max Budget"
                >
                  <AiOutlineClose size={16} />
                </button>
              )}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "paid_amount", // This is the new column for budget
        header: "Paid Amount",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "pending_amount",
        header: "Pending Amount",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row }) => {
          const pendingAmount = row?.original?.pending_amount; // Get pending amount
          const budget = row.original.budget; // Get budget amount

          // Determine background and text colors
          let textColor = "black";

          if (pendingAmount === 0) {
            textColor = "gray";
          } else if (pendingAmount === budget) {
            textColor = "red";
          } else if (pendingAmount < budget) {
            textColor = "orange";
          }

          return (
            <div
              style={{
                color: textColor,
                borderRadius: "0.25rem",
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {pendingAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "over_Paid",
        header: "Over Paid Amount",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row }) => {
          const overPaidAmount = row?.original?.payment_details?.overPaidAmount; // Get pending amount
          const budget = row.original.budget; // Get budget amount

          // Determine background and text colors
          let textColor = "black";

          if (overPaidAmount > 0) {
            textColor = "#006400";
          } else {
            textColor = "gray";
          }

          return (
            <div
              style={{
                color: textColor,
                borderRadius: "0.25rem",
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {overPaidAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "enquiryStatus", // Adjust this to match your actual data structure
        header: "Enquiry Status",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={enquiryStausData}
            selectedId={enquiry_status_id}
            setSelectedId={setEnquiryStatusId}
            column={column}
            placeholder="Select enquiry status"
          />
        ),
        Cell: ({ cell }) => {
          const enquiryStatus = cell.getValue();
          const statusName = enquiryStatus?.name || "N/A"; // Use the name property for display
          // Function to determine text color based on status name
          const getEnquiryStatusTextColor = (status) => {
            switch (status) {
              case "New":
                return "text-blue-600"; // Blue for New
              case "Reviewing Options":
                return "text-yellow-600"; // Yellow for Reviewing Options
              case "Awaiting Customer Confirmation":
                return "text-orange-600"; // Orange for Awaiting Customer Confirmation
              case "Booked":
                return "text-green-600"; // Green for Booked
              case "Closed":
                return "text-gray-600"; // Gray for Closed
              default:
                return "text-red-600"; // Default red for unknown status
            }
          };

          return (
            <div className="relative inline-flex items-center gap-1">
              <span className="group relative ml-2 cursor-pointer">
                {/* Info Icon */}
                <AiOutlineInfoCircle className="text-gray-600 hover:text-gray-800" />
                {/* Tooltip */}
                <span className="absolute -top-8 left-0 hidden w-48 bg-gray-700 p-2 text-xs text-white group-hover:block">
                  <strong>Status Info:</strong>
                  <br />
                  ID: {enquiryStatus?.id || "N/A"}
                  <br />
                  Status: {statusName}
                  <br />
                  Slug: {enquiryStatus?.slug || "N/A"}
                </span>
              </span>
              <span className={getEnquiryStatusTextColor(statusName)}>
                {statusName}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "enquiry_payment_status", // Adjust this to match your actual data structure
        header: "Payment Status",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={enquiryPaymentStausData}
            selectedId={enquiry_payment_status}
            setSelectedId={setEnquiryPaymentStatus}
            column={column}
            placeholder="Select enquiry payment status"
          />
        ),
        Cell: ({ cell }) => {
          const paymentStatus = cell.getValue();

          // Helper function to convert status to formatted display text
          const formatPaymentStatus = (status) => {
            const formattedStatus = {
              over_paid: "Over Paid",
              paid: "Paid",
              pending: "Pending",
              not_paid: "Not Paid",
            };
            return formattedStatus[status] || status; // Fallback to original if status is not in the map
          };

          // Function to determine text color based on payment status
          const getPaymentStatusTextColor = (status) => {
            switch (status) {
              case "over_paid":
                return "text-green-800 "; // Green for Paid/Over Paid
              case "paid":
                return "text-green-500"; // Green for Paid/Over Paid
              case "pending":
                return "text-orange-500"; // Orange for Pending
              case "not_paid":
                return "text-red-600"; // Red for Not Paid
              default:
                return "text-gray-600"; // Default gray for unknown status
            }
          };

          return (
            <p>
              <span className={getPaymentStatusTextColor(paymentStatus)}>
                {formatPaymentStatus(paymentStatus)}
              </span>
            </p>
          );
        },
      },
      {
        accessorKey: "invoice_number",
        header: "Invoice",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "booking_reference",
        header: "Booking Reference",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "special_requests",
        header: "Special Request",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "created_at", // Adjust this to match your actual data structure
        header: "Created Date",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={createdAtFilterDate}
            setSelectedDate={setCreatedAtFilterDate}
          />
        ),

        Cell: ({ cell }) => {
          const createdAt = new Date(cell.getValue());
          const date = createdAt.toLocaleDateString(); // Get the date portion
          const time = createdAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
              "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
              "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "updated_at", // Adjust this to match your actual data structure
        header: "Updated Date",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={updatedFilterDate}
            setSelectedDate={setUpdatedFilterDate}
          />
        ),
        Cell: ({ cell }) => {
          const updatedAt = new Date(cell.getValue());
          const date = updatedAt.toLocaleDateString(); // Get the date portion
          const time = updatedAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
              "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
              "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "assignedToUser",
        header: "Assigned To",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={AssignToData}
            selectedId={AssignToUserId}
            setSelectedId={setAssignToUserId}
            column={column}
            placeholder="Assign to..."
          />
        ),
        Cell: ({ cell }) => {
          const assignedUser = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              {assignedUser ? (
                <div className="tooltip" data-tip="Profile">
                  <img
                    onClick={() => handleOpenModal(assignedUser)} // Wrap in an arrow function for passing data
                    className="h-10 w-10 cursor-pointer rounded-full"
                    src={assignedUser?.full_path}
                    alt={assignedUser?.name}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = avtar; // Default avatar
                    }}
                  />
                </div>
              ) : (
                "N/A"
              )}
              {assignedUser && (
                <div>
                  <p>{assignedUser?.name}</p>
                  <p>{assignedUser?.phone_no}</p>
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "createdByUser",
        header: "Created By",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={createdByData}
            selectedId={createdById}
            setSelectedId={setCreatedById}
            column={column}
            placeholder="created By..."
          />
        ),
        Cell: ({ cell }) => {
          const assignedBy = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              <div className="tooltip" data-tip="Profile">
                <img
                  onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function
                  className="h-10 w-10 cursor-pointer rounded-full"
                  src={assignedBy?.full_path}
                  alt={assignedBy?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avtar; // Default avatar
                  }}
                />
              </div>
              <div>
                <p>{assignedBy?.name}</p>
                <p>{assignedBy?.phone_no}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "updatedByUser",
        header: "updated By",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={createdByData}
            selectedId={updatedById}
            setSelectedId={setUpdatedById}
            column={column}
            placeholder="Updated By..."
          />
        ),
        Cell: ({ cell }) => {
          const assignedBy = cell.getValue();
          return (
            <div className="flex items-center gap-2">
              {assignedBy ? (
                <>
                  <div className="tooltip" data-tip="Profile">
                    <img
                      onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function for passing data
                      className="h-10 w-10 cursor-pointer rounded-full"
                      src={assignedBy?.full_path}
                      alt={assignedBy?.name || "User Avatar"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avtar; // Default avatar
                      }}
                    />
                  </div>
                  <div>
                    <p>{assignedBy?.name}</p>
                    <p>{assignedBy?.phone_no}</p>
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: true, // Disable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={[
              { id: "pending", name: "Pending" },
              { id: "reject", name: "Rejected" },
              { id: "accept", name: "Accepted" },
            ]}
            selectedId={statusFilter}
            setSelectedId={setStatusFilter}
            column={column}
            placeholder="Status Filter"
            getStatusColor={getStatusTextColor}
          />
        ),
        Cell: ({ row, cell }) => {
          const status = cell.getValue();
          const statusName = status;

          return (
            <div>
              {api === "hotelassignenquirylist" && status !== "accept" ? (
                <select
                  name={"status"}
                  className={`relative inline-flex cursor-pointer items-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${getStatusTextColor(
                    statusName
                  )}`}
                  disabled={status === "accept" ? true : false}
                  onChange={async (e) => {
                    const data = {
                      id: row.original.id,
                      status: e.target.value,
                    };
                    try {
                      const response =
                        await userResources.hotelAssignEnquiryAccepter(data); // Call the API with ID and status
                      console.log(response);
                      if (response.status) {
                        toast.success(response?.data?.message?.flash_message);
                      }

                      getHotelList(
                        pagination.pageIndex,
                        pagination.pageSize,
                        globalFilter
                      );

                      console.log(response);
                    } catch (error) {
                      console.log(error);
                    }
                  }} // Handle status change
                >
                  <option value={statusName}>{statusName}</option>
                  <option value="reject">Reject</option>
                  <option value="accept">Accept</option>
                </select>
              ) : (
                <div className="relative inline-flex items-center gap-1">
                  <p className={getStatusTextColor(statusName)}>{statusName}</p>
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "history",
        header: "History",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ row }) => (
          <div
            className="flex space-x-1"
            onClick={() =>
              navigate("/admin/hotel-enquiry-history", {
                state: row?.original?.children,
              })
            }
          >
            <SubmitButton
              add="History"
              extraClass={"bg-purple-500 rounded-lg"}
            />
          </div>
        ),
      },
      {
        accessorKey: "action",
        header: "Action",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Edit
              className="cursor-pointer text-blue-500"
              onClick={() => {
                const rowDataWithNavigateTo = {
                  ...row.original,
                  NavigateTo: api,
                };
                navigate(`/admin/create-enquiry/hotel`, {
                  state: rowDataWithNavigateTo,
                });
              }}
            />
            {userInfo?.data?.role_id ===
              Number(process.env.REACT_APP_ROLE_SUPERADMIN) ||
            userInfo?.data?.role_id ===
              Number(process.env.REACT_APP_ROLE_ADMIN) ? (
              <ConfirmModal
                onConfirm={handleDelete}
                title="Delete Hotel Enquiry"
                message="Are you sure you want to delete this Hotel enquiry?"
                employeeId={row.original.id}
              />
            ) : null}
          </div>
        ),
      },
    ],
    [
      enquiryData,
      enquiry_source_id,
      checkInDate,
      checkOutDate,
      createdAtFilterDate,
      updatedFilterDate,
      enquiry_status_id,
      enquiry_payment_status,
      AssignToUserId,
      createdById,
      budgetRange,
      updatedById,
      statusFilter,
    ]
  );

  // Placeholder functions for handling actions
  const handleEdit = (enquiry) => {
    console.log("Edit Enquiry:", enquiry);
  };

  const handleDelete = async (id) => {
    // Show loading toast
    const loadingToastId = toast.loading("Deleting enquiry...");

    try {
      await userResources.hotelEnquiryDelete(id); // Attempt to delete the airline

      // Update toast to show success
      toast.update(loadingToastId, {
        render: "Deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Refresh the enquiry list
      getHotelList(pagination.pageIndex, pagination.pageSize, globalFilter);
    } catch (error) {
      console.error("Error deleting enquiry:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to delete enquiry. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      // Dismiss the loading toast if not already dismissed
      if (toast.isActive(loadingToastId)) {
        toast.dismiss(loadingToastId);
      }
    }
  };

  const sendEmailToAll = () => {
    const emails = data.map((item) => item.email).join(",");
    const subject = "Hello Everyone!";
    const body = "Hi Team,\n\n";
    const mailtoLink = `mailto:${emails}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  // const sendWhatsAppToAll = () => {
  //   const message = "Hello team, this is a test message.";
  //   const phoneNumbers = data.map(
  //     (employee) => employee.phone_no.replace(/\D/g, "") // Ensure this is the correct key for phone numbers
  //   );
  //   const whatsappLinks = phoneNumbers.map(
  //     (phoneNumber) =>
  //       `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
  //   );

  //   whatsappLinks.forEach((link) => {
  //     window.open(link);
  //   });
  // };

  // pagination
  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };
  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  // console.log(columnsFilterValue, "columnsFilterValue");

  const handleFilterValues = () => {
    let titleFound = false;
    let nameFound = false;
    let phoneFound = false;
    let emailFound = false;
    let booking_reference_Found = false;
    let invoiceFound = false;

    columnsFilterValue.forEach((key) => {
      if (key.id === "full_name") {
        setFullName(key.value);
        nameFound = true;
      } else if (key.id === "phone_number") {
        setPhoneNumber(key.value);
        phoneFound = true;
      } else if (key.id === "email") {
        setEmailFilter(key.value);
        emailFound = true;
      } else if (key.id === "title") {
        setTitle(key.value);
        titleFound = true;
      } else if (key.id === "booking_reference") {
        setBookingReference(key.value);
        booking_reference_Found = true;
      } else if (key.id === "invoice_number") {
        setInvoice(key.value);
        invoiceFound = true;
      }
    });

    // If keys were not found, set values to null
    if (!nameFound) {
      setFullName(null);
    }
    if (!phoneFound) {
      setPhoneNumber(null);
    }
    if (!emailFound) {
      setEmailFilter(null);
    }
    if (!titleFound) {
      setTitle(null);
    }
    if (!booking_reference_Found) {
      setBookingReference(null);
    }
    if (!invoiceFound) {
      setInvoice(null);
    }
  };

  useEffect(() => {
    handleFilterValues();
  }, [columnsFilterValue]);

  useEffect(() => {
    getHotelList(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [
    pagination,
    globalFilter,
    fullName,
    title,
    checkInDate,
    checkOutDate,
    emailFilter,
    phone_number,
    enquiry_payment_status,
    enquiry_source_id,
    AssignToUserId,
    bookingReference,
    enquiry_status_id,
    isError,
    createdById,
    invoice,
    createdAtFilterDate,
    updatedFilterDate,
    updatedById,
    statusFilter,
  ]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getHotelList(pagination.pageIndex, pagination.pageSize, globalFilter);
    }, 1000); // 1 second delay

    return () => clearTimeout(delay); // Clear timeout if min or max changes within 1 second
  }, [budgetRange.max, budgetRange.min]);

  useEffect(() => {
    getEnquirySource();
    getHotelList();
  }, []);
  return (
    <section className="mx-2 mt-0 sm:mt-5">
      <ScrollToTopOnRouteChange />
      <div
        className={`mb-4 grid grid-cols-1 items-center justify-between gap-4 ${
          location?.state?.name ? "md:grid-cols-3" : "sm:grid-cols-1 md:grid-cols-2"
        }`}
      >
        {location?.state?.name && (
          <Widget
            title={location?.state?.name + " Enquirys"}
            icon={
              location?.state?.filterWithEmployee?.image ? (
                <div className="flex h-[45px] w-[45px] items-center justify-center overflow-hidden rounded-full border-0 border-white bg-pink-400 dark:!border-navy-700">
                  <img
                    className="h-full w-full  rounded-full"
                    src={location?.state?.filterWithEmployee?.image}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = avtar; // Default avatar
                    }}
                    alt="Selected"
                  />
                </div>
              ) : (
                <RiMessage2Line className="h-7 w-7 text-purple-500 dark:text-white" />
              )
            }
            imagePadding={
              location?.state?.filterWithEmployee?.image ? "p-0" : "p-2"
            }
            heading={location?.state?.filterWithEmployee?.name}
            // subtitle={`${data?.totalEnquiries || 0}`}
            cardClasses={"h-16"}
            cardbgColor={"bg-purple-500 cursor-pointer"}
            titleColor={"text-white"}
          />
        )}
        {/* <button className="btn">{location?.state?.name} Enquirys</button> */}
        <div className="grid grid-cols-1 gap-4 rounded-[20px] bg-gray-300 p-2 dark:bg-white sm:grid-cols-2">
          <label
            className={`flex items-end justify-between gap-5 whitespace-nowrap text-sm font-medium text-gray-900 ${
              location?.state?.name ? "sm:flex-col sm:gap-0" : ""
            } sm:items-start `}
          >
            Start Date:{" "}
            <DateFilter
              selectedDate={startDate}
              setSelectedDate={setStartDate}
            />
          </label>
          <label
            className={`flex items-end justify-between gap-5 whitespace-nowrap text-sm font-medium text-gray-900 ${
              location?.state?.name ? "sm:flex-col sm:gap-0" : ""
            } sm:items-start `}
          >
            End Date:{" "}
            <DateFilter selectedDate={endDate} setSelectedDate={setEndDate} />
          </label>
        </div>
        <SubmitButton
          onClick={sendEmailToAll}
          add="Send Email to All"
          extraClass={
            "h-full bg-green-500 hover:bg-green-700 rounded-[20px] mt-2 sm:mt-0"
          }
        />
        {/* <button
          onClick={sendWhatsAppToAll}
          className="rounded bg-green-600 px-4 py-2 text-white hover:bg-green-700"
        >
          Send WhatsApp to All
        </button> */}
      </div>

      {/* Modal for User Info */}
      <div className="">
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>

      <MaterialReactTable
        columns={columns}
        data={data}
        state={{
          pagination,
          isLoading,
          showProgressBars: isLoading,
          showAlertBanner: isError,
        }} // Use both states here
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                guestren: "Error loading data",
              }
            : undefined
        }
        muiTableBodyRowProps={{
          sx: {
            height: "110px", // Set the desired row height here
          },
        }}
        enableColumnFilters={true} // Pass the current visibility state
        initialState={{ showColumnFilters: true }}
        onColumnFiltersChange={setColumnsFilterValue}
        enableSorting={false}
        enableColumnOrdering={false}
        enableRowActions
        enablePagination
        onPaginationChange={handlePaginationChange}
        manualPagination
        rowCount={totalRows}
        pageCount={Math.ceil(totalRows / pagination.pageSize)}
        isLoading={isLoading}
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
        }}
        enableGlobalFilter // Keep this true to allow global filtering
        onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
        globalFilter={globalFilter} // Pass the state variable to the table
        // editingMode="modal" // Ensure this is correctly set
        // enableEditing // This should also be true
        renderRowActions={({ row }) => (
          <div className="flex flex-nowrap gap-4">
            <button
              className="text-xl text-primary"
              onClick={() =>
                window.open(
                  `mailto:${row.original.email}?subject=Hello ${row.original.fullName}!`
                )
              }
            >
              <FaMailBulk />
            </button>
            <button
              className="text-xl text-success"
              onClick={() =>
                window.open(
                  `https://wa.me/${row.original.phone_number.replace(
                    /\D/g,
                    ""
                  )}?text=Hello ${row.original.fullName}!`
                )
              }
            >
              <FaWhatsapp />
            </button>
          </div>
        )}
      />
    </section>
  );
}
