import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { AiOutlineInfoCircle } from "react-icons/ai";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import { avtar } from "utils/images";
import { useLocation } from "react-router-dom";
import getStatusTextColor from "components/StatusColorFunction/StatusColorFunction";
export default function HotelEnquiryHistory({ History }) {
  const location = useLocation();
  const historydata = History || location.state;

  const [data, setData] = useState(historydata?.reverse() || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setUserProfile({}); // Reset user profile on close
  };

  // Utility function to format a date as DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return "Invalid Date"; // Handle invalid date inputs

    // Format date to DD-MM-YYYY
    const formattedDate = dateObj
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");
    return formattedDate;
  };
  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "parent_id",
        header: "Parent ID",
      },
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "full_name",
        header: "Customer Name",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "email",
        header: "Email Address",
        enableClickToCopy: true,
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },

      {
        accessorKey: "enquirySource",
        header: "Enquiry Source",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "invoice_number",
        header: "Invoice",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "destination",
        header: "Destination",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
        filterVariant: "select",
      },
      {
        accessorKey: "check_in_date",
        header: "Check In Date",

        Cell: ({ cell }) => formatDate(cell.getValue()), // Use the utility function
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "check_out_date",
        header: "Check Out Date",
        Cell: ({ cell }) => formatDate(cell.getValue()), // Use the utility function
      },
      {
        header: "Rooms & Guest",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ row }) => {
          const room = row.original.room ?? "N/A";
          const guest = row.original.guest ?? "N/A";

          return (
            <div className="whitespace-nowrap">
              <p>Room: {room}</p>
              <p>Guest: {guest}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "budget",
        header: "Budget",
        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "paid_amount", // This is the new column for budget
        header: "Paid Amount",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "enquiryStatus", // Adjust this to match your actual data structure
        header: "Enquiry Status",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue()?.name || "N/A"; // Ensure we get the name, or "N/A" if missing
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.enquiryStatus?.name
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue}
            </span>
          );
        },
      },
      {
        accessorKey: "enquiry_payment_status", // Adjust this to match your actual data structure
        header: "Payment Status",
        Cell: ({ cell, row, table }) => {
          // Helper function to convert status to formatted display text
          const formatPaymentStatus = (status) => {
            const formattedStatus = {
              over_paid: "Over Paid",
              paid: "Paid",
              pending: "Pending",
              not_paid: "Not Paid",
            };
            return formattedStatus[status] || status; // Fallback to original if status is not in the map
          };
          const currentValue = cell.getValue() || "N/A"; // Ensure we get the name, or "N/A" if missing
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.enquiry_payment_status
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            nextValue !== null &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {formatPaymentStatus(currentValue)}
            </span>
          );
        },
      },

      {
        accessorKey: "special_requests",
        header: "Special Request",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "created_at", // Adjust this to match your actual data structure
        header: "Created Date",
        filterVariant: "select",

        Cell: ({ cell }) => {
          const createdAt = new Date(cell.getValue());
          const date = createdAt.toLocaleDateString(); // Get the date portion
          const time = createdAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
              "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
              "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "updated_at", // Adjust this to match your actual data structure
        header: "Updated Date",

        Cell: ({ cell }) => {
          const updatedAt = new Date(cell.getValue());
          const date = updatedAt.toLocaleDateString(); // Get the date portion
          const time = updatedAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
              "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
              "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "assignedToUser",
        header: "Assigned To",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column

        Cell: ({ cell }) => {
          const assignedUser = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              {assignedUser ? (
                <div>
                  <img
                    onClick={() => handleOpenModal(assignedUser)} // Wrap in an arrow function for passing data
                    className="h-10 w-10 cursor-pointer rounded-full"
                    src={assignedUser?.full_path}
                    alt={assignedUser?.name}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = avtar; // Default avatar
                    }}
                  />
                </div>
              ) : (
                "N/A"
              )}
              {assignedUser && (
                <div>
                  <p>{assignedUser?.name}</p>
                  <p>{assignedUser?.phone_no}</p>
                </div>
              )}
            </div>
          );
        },
      },
      // {
      //   accessorKey: "createdByUser",
      //   header: "Created By",

      //   Cell: ({ cell }) => {
      //     const assignedBy = cell.getValue();

      //     return (
      //       <div className="flex items-center gap-2">
      //         <div>
      //           <img
      //             onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function
      //             className="h-10 w-10 cursor-pointer rounded-full"
      //             src={assignedBy?.full_path}
      //             alt={assignedBy?.name}
      //             onError={(e) => {
      //               e.target.onerror = null;
      //               e.target.src = avtar; // Default avatar
      //             }}
      //           />
      //         </div>
      //         <div>
      //           <p>{assignedBy?.name}</p>
      //           <p>{assignedBy?.phone_no}</p>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      {
        accessorKey: "updatedByUser",
        header: "updated By",

        Cell: ({ cell }) => {
          const assignedBy = cell.getValue();
          return (
            <div className="flex items-center gap-2">
              {assignedBy ? (
                <>
                  <div className="tooltip" data-tip="Profile">
                    <img
                      onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function for passing data
                      className="h-10 w-10 cursor-pointer rounded-full"
                      src={assignedBy?.full_path}
                      alt={assignedBy?.name || "User Avatar"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avtar; // Default avatar
                      }}
                    />
                  </div>
                  <div>
                    <p>{assignedBy?.name}</p>
                    <p>{assignedBy?.phone_no}</p>
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row, cell }) => {
          const status = cell.getValue();
          const statusName = status;

          return (
            <div className="relative inline-flex items-center gap-1">
              <p className={getStatusTextColor(statusName)}>{statusName}</p>
            </div>
          );
        },
      },
    ],
    [data]
  );

  useEffect(() => {
    if (historydata) {
      setData(historydata.reverse()); // Ensure proper state update
    }
  }, [historydata]);

  return (
    <section className="">
      <ScrollToTopOnRouteChange />
      <div className="">
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>

      <div>
        {historydata.map((e, index) => {
          <h2 className="my-5 text-2xl" key={index}>
            Search Enquiry History {e?.parent_id}
          </h2>;
        })}
      </div>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters={false} // Disable column filters
        initialState={{
          showColumnFilters: false, // Hide column filters
          pagination: { pageSize: data.length }, // Set the page size to the total data length
        }}
        enableColumnOrdering={false} // Disable column ordering
        enableGlobalFilter={false} // Disable global filter
        enablePagination={false} // Disable pagination feature
        manualPagination={true} // Ensure pagination is controlled manually
      />
    </section>
  );
}
