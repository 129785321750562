import { useNavigate } from "react-router-dom";

function Card(props) {
  const navigate = useNavigate();

  const {
    variant,
    extra,
    cardClasses,
    navigateTo,
    children,
    data,
    id,
    name,
    thisSearchFor,
    assignListNavigate,
    createdListNavigate,
    filterWithEmployee,
    ...rest
  } = props;
  return (
    <section>
      {navigateTo ? (
        <div
          className={`!z-5 relative flex flex-col rounded-[20px] bg-clip-border shadow-3xl shadow-shadow-500  dark:text-white dark:shadow-none ${
            extra ? extra : "bg-white dark:!bg-navy-800"
          }`}
          {...rest}
          onClick={() =>
            navigate(
              navigateTo,
              data
                ? {
                    state: {
                      ...data,
                      assignListNavigate: assignListNavigate,
                      createdListNavigate: createdListNavigate,
                    },
                  }
                : {
                    state: {
                      id: id,
                      name: name,
                      disabledFilter: false,
                      thisSearchFor: thisSearchFor,
                      assignListNavigate: assignListNavigate,
                      createdListNavigate: createdListNavigate,
                      filterWithEmployee: filterWithEmployee,
                    },
                  }
            )
          }
        >
          {children}
        </div>
      ) : (
        <div
          className={`!z-5 relative  flex flex-col rounded-[20px] ${cardClasses} bg-clip-border shadow-3xl shadow-shadow-500  dark:text-white dark:shadow-none ${
            extra ? extra : "bg-white dark:!bg-navy-800"
          }`}
          {...rest}
        >
          {children}
        </div>
      )}
    </section>
  );
}

export default Card;
