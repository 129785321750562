import React from "react";
import EnquiryList from "./EnquiryList";

export default function CreatedEnquiryList() {
  return (
    <section>
      <EnquiryList api="createdenquiryList" />
    </section>
  );
}
