import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaPlane } from "react-icons/fa"; // Importing the plane icon for departments
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton/SubmitButton";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

export default function Branch() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [branchData, setBranchData] = useState([]);
  const [editingBranch, setEditingBranch] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    slug: "",
  });
  const [errors, setErrors] = useState({});

  const getBranch = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    try {
      const response = await userResources.branch(
        pageIndex || 0,
        pageSize || 5,
        globalFilter || ""
      );
      setBranchData(response.data.data);
      setTotalRows(response.data.paginate_data.total); // Set total rows for pagination
    } catch (error) {
      console.error("Error fetching department data:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };

  const validateField = (name, value) => {
    // Field validation logic
    let errorMessage = "";
    if (name === "name" && !value) {
      errorMessage = "Branch name is required.";
    }

    return errorMessage;
  };

  const handleChange = (name, value) => {
    const fieldValue = value.target.value;

    // Update formData state
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: fieldValue,
      };

      // If the name field is being updated, generate the slug
      if (name === "name") {
        updatedData.slug = fieldValue.toLowerCase().replace(/\s+/g, "_"); // Create slug from name
      }

      return updatedData;
    });

    // Validate the field and set error message if needed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, fieldValue),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields on form submission
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const errorMessage = validateField(key, formData[key]);
      if (errorMessage) {
        newErrors[key] = errorMessage;
      }
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the errors before submitting.");
      return; // If there are errors, do not proceed
    }

    // Show loading toast and disable the submit button
    setDisabled(true);
    const loadingToastId = toast.loading("Submitting...");

    try {
      if (editingBranch) {
        // Update existing department
        await userResources.addBranch({
          id: editingBranch.id,
          ...formData,
        });
        setEditingBranch(null);
        getBranch();

        // Update toast to success
        toast.update(loadingToastId, {
          render: "Branch updated successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        // Add new department
        await userResources.addBranch({
          ...formData,
        });
        getBranch();

        // Update toast to success
        toast.update(loadingToastId, {
          render: "Branch added successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      // Reset form data after successful submission
      setFormData({
        name: "",
        slug: "",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to submit form. Please try again.";

      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      // Optionally, handle individual field errors if needed
      if (error.response?.data?.errors) {
        const { errors } = error.response.data;

        // Filter out the slug error if it exists
        const filteredErrors = { ...errors };
        delete filteredErrors.slug; // Remove slug error from the errors object

        setErrors(filteredErrors); // Update state with filtered errors
      }
    } finally {
      // Re-enable the submit button regardless of success or error
      setDisabled(false);
    }
  };

  const airlinesColumns = [
    { accessorKey: "name", header: "Branch" },
    { accessorKey: "slug", header: "Slug" },
    {
      accessorKey: "created_at",
      header: "Created at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "updated_at",
      header: "Updated at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <Edit
            className="cursor-pointer text-blue-500"
            onClick={() => handleEdit(row.original)}
            aria-label={`Edit ${row.original.name}`}
          />
          {/* <Delete
            className="cursor-pointer text-red-500"
            onClick={() => handleDelete(row.original.id)}
            aria-label={`Delete ${row.original.name}`}
          /> */}
          <ConfirmModal
            onConfirm={() => handleDelete(row.original.id)} // Wrap in an arrow function
            title="Delete Branch"
            message="Are you sure you want to delete this Branch?"
          />
        </div>
      ),
    },
  ];

  const handleEdit = (department) => {
    setFormData({
      name: department.name,
      slug: department.slug,
    });
    setEditingBranch(department);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setEditingBranch(null);
    setFormData({ name: "", slug: "" });
    setErrors({});
  };

  const handleDelete = async (id) => {
    // Open a confirmation dialog (you may want to replace this with a modal)
    // const confirmDelete = window.confirm(
    //   "Are you sure you want to delete this department?"
    // );
    // if (!confirmDelete) return; // Exit if the user cancels

    // Show loading toast
    const loadingToastId = toast.loading("Deleting department...");

    try {
      await userResources.branchDelete(id); // Attempt to delete the department

      // Update toast to show success
      toast.update(loadingToastId, {
        render: "Branch deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Refresh the department list
      getBranch(pagination.pageIndex, pagination.pageSize, globalFilter);
    } catch (error) {
      console.error("Error deleting department:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to delete department. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      // Dismiss the loading toast if not already dismissed
      if (toast.isActive(loadingToastId)) {
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };

  // Calculate total pages
  const totalPages = Math.ceil(branchData.length / pagination.pageSize);

  // Navigate to first page
  const goToFirstPage = () => {
    setPagination({ ...pagination, pageIndex: 0 });
  };

  // Navigate to last page
  const goToLastPage = () => {
    setPagination({ ...pagination, pageIndex: totalPages - 1 });
  };

  useEffect(() => {
    getBranch();
  }, []);

  useEffect(() => {
    getBranch(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  return (
    <section className="mt-6">
      <ScrollToTopOnRouteChange />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        {/* Add Branch Form */}
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            {editingBranch ? "Edit Branch" : "Add Branch"}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-2">
              <InputField
                label="Branch Name"
                name="name"
                type="text"
                placeholder="Branch Name"
                value={formData.name}
                onChange={(value) => handleChange("name", value)}
                state={errors.name ? "error" : ""}
                errorMessage={errors.name}
              />

              <InputField
                label="Slug"
                name="slug"
                type="text"
                placeholder="slug"
                value={formData.slug} // Use formData.slug for the value
                onChange={(value) => handleChange("slug", value)}
                state={errors.slug ? "error" : ""}
                errorMessage={errors.slug}
                disabled={true}
              />
            </div>
            <div className="mt-6 flex space-x-2">
              {editingBranch && (
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-full rounded-lg border border-red-500 p-2 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
                >
                  Cancel
                </button>
              )}
              {/* <button
                type="submit"
                className="w-full rounded-lg bg-blue-600 p-2 text-white hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600"
              >
                {editingBranch ? "Update Branch" : "Add Branch"}
              </button> */}

              <SubmitButton
                editing={editingBranch}
                disabled={disabled}
                add="Add Branch"
                edit="Update Branch"
              />
            </div>
          </form>
        </div>

        {/* Airlines Table */}
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            Branch List
          </h3>
          <MaterialReactTable
            columns={airlinesColumns}
            data={branchData}
            state={{ pagination, isLoading }} // Use both states here
            // state={{ pagination, isLoading, showProgressBars: isLoading }} // Use both states here
            enablePagination
            onPaginationChange={handlePaginationChange}
            manualPagination
            rowCount={totalRows}
            pageCount={Math.ceil(totalRows / pagination.pageSize)}
            isLoading={isLoading}
            enableColumnFilters={false}
            enableSorting={false}
            enableColumnOrdering={false}
            muiTablePaginationProps={{
              showFirstButton: true,
              showLastButton: true,
            }}
            enableGlobalFilter // Keep this true to allow global filtering
            onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
            globalFilter={globalFilter} // Pass the state variable to the table
          />
        </div>
      </div>
    </section>
  );
}
