import userResources from "api/userResources";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout, selectUserInfo } from "../../redux/userSlice";
import { MdLock } from "react-icons/md";

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  console.log(userInfo);

  return (
    <div>
      <li
        onClick={async () => {
          try {
            // Call the logout API
            const response = await userResources.markAttendance();

            if (response.status) {
              // Call the markAttendance API
              await await userResources.logout();
            }
            // Dispatch the logout action
            dispatch(logout());

            // Show a success message
            toast.success("You've successfully logged out! See you next time!");

            // Navigate to the home page upon successful logout
            if (
              userInfo?.data?.role_id === process.env.REACT_APP_ROLE_ADMIN ||
              process.env.REACT_APP_ROLE_EMPLOYEE ||
              process.env.REACT_APP_ROLE_SUPERADMIN
            ) {
              navigate("/agencyLogin");
            } else {
              navigate("/");
            }
          } catch (error) {
            // Handle any errors (e.g., show a toast message)
            toast.error("Logout failed. Please try again.");
            console.error("Logout failed:", error);
          }
        }}
        className="mx-8 flex cursor-pointer font-semibold "
      >
        <MdLock className="mr-4 h-6 w-6 text-red-500  dark:text-white" />
        <p className="text-lg text-red-500 hover:text-red-700 dark:hover:text-white">
          Logout
        </p>
      </li>
    </div>
  );
}
