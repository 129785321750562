const airports = [
  {
    StateName: null,
    IATACode: "DEL",
    CountryCode: "IN",
    Country: "IN",
    RegionCode: null,
    RegionId: 12,
    LAT: 28.5665,
    LON: 77.103104,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 100696,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "DEL",
    Terminal: null,
    TimeZone: "Asia/Kolkata",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Indira Gandhi Intl",
    CityName: "Delhi",
    CountryName: "India",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "DEL - Indira Gandhi Intl",
    DisplayNameOnPriceDetails: "DEL, Indira Gandhi Intl",
  },
  {
    StateName: null,
    IATACode: "PMV",
    CountryCode: "VE",
    Country: "VE",
    RegionCode: null,
    RegionId: 11,
    LAT: 10.9126033782958,
    LON: -63.9665985107421,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 798,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "PMV",
    Terminal: null,
    TimeZone: "America/Caracas",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Delcaribe Gen S",
    CityName: "Porlamar",
    CountryName: "Venezuela",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "PMV - Delcaribe Gen S",
    DisplayNameOnPriceDetails: "PMV, Delcaribe Gen S",
  },
  {
    StateName: "Texas",
    IATACode: "DRT",
    CountryCode: "US",
    Country: "US",
    RegionCode: "TX",
    RegionId: 5,
    LAT: 29.3742008209,
    LON: -100.927001953,
    IsInternational: true,
    IsGroup: false,
    IsCity: true,
    Weight: 316,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "DRT",
    Terminal: null,
    TimeZone: "America/Chicago",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Del Rio Intl",
    CityName: "Del Rio",
    CountryName: "United States of America",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "DRT - Del Rio Intl",
    DisplayNameOnPriceDetails: "DRT, Del Rio Intl",
  },
  {
    StateName: "Michigan",
    IATACode: "ESC",
    CountryCode: "US",
    Country: "US",
    RegionCode: "MI",
    RegionId: 5,
    LAT: 45.7226982117,
    LON: -87.0936965942,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 260,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "ESC",
    Terminal: null,
    TimeZone: "America/Detroit",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Delta County",
    CityName: "Escanaba",
    CountryName: "United States of America",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "ESC - Delta County",
    DisplayNameOnPriceDetails: "ESC, Delta County",
  },
  {
    StateName: null,
    IATACode: "IAO",
    CountryCode: "PH",
    Country: "PH",
    RegionCode: null,
    RegionId: 24,
    LAT: 9.8591,
    LON: 126.014,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 0,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "IAO",
    Terminal: null,
    TimeZone: "Asia/Manila",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Sayak Airport",
    CityName: "Del Carmen",
    CountryName: "Philippines",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "IAO - Sayak Airport",
    DisplayNameOnPriceDetails: "IAO, Sayak Airport",
  },
  {
    StateName: "Texas",
    IATACode: "DLF",
    CountryCode: "US",
    Country: "US",
    RegionCode: "TX",
    RegionId: 5,
    LAT: 29.359501,
    LON: -100.778002,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 0,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "DRT",
    Terminal: null,
    TimeZone: "America/Chicago",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Laughlin Afb",
    CityName: "Del Rio",
    CountryName: "United States of America",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "DLF - Laughlin Afb",
    DisplayNameOnPriceDetails: "DLF, Laughlin Afb",
  },
  {
    StateName: null,
    IATACode: "DLV",
    CountryCode: "AU",
    Country: "AU",
    RegionCode: "NT",
    RegionId: 15,
    LAT: -12.55,
    LON: 130.685,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 0,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "DLV",
    Terminal: null,
    TimeZone: "Australia/Darwin",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Delissaville",
    CityName: "Delissaville",
    CountryName: "Australia",
    AirportRepresentWholeCity: false,
    IsAirport: true,
    Location: null,
    DisplayName: "DLV - Delissaville",
    DisplayNameOnPriceDetails: "DLV, Delissaville",
  },
  // Additional entries
  {
    StateName: "California",
    IATACode: "SFO",
    CountryCode: "US",
    Country: "US",
    RegionCode: "CA",
    RegionId: 6,
    LAT: 37.618999,
    LON: -122.375,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 150000,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "SFO",
    Terminal: null,
    TimeZone: "America/Los_Angeles",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "San Francisco Intl",
    CityName: "San Francisco",
    CountryName: "United States of America",
    AirportRepresentWholeCity: true,
    IsAirport: true,
    Location: null,
    DisplayName: "SFO - San Francisco Intl",
    DisplayNameOnPriceDetails: "SFO, San Francisco Intl",
  },
  {
    StateName: "Tokyo",
    IATACode: "NRT",
    CountryCode: "JP",
    Country: "JP",
    RegionCode: null,
    RegionId: 30,
    LAT: 35.764702,
    LON: 140.386002,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 125000,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "TYO",
    Terminal: null,
    TimeZone: "Asia/Tokyo",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Narita Intl",
    CityName: "Tokyo",
    CountryName: "Japan",
    AirportRepresentWholeCity: true,
    IsAirport: true,
    Location: null,
    DisplayName: "NRT - Narita Intl",
    DisplayNameOnPriceDetails: "NRT, Narita Intl",
  },
  {
    StateName: null,
    IATACode: "SYD",
    CountryCode: "AU",
    Country: "AU",
    RegionCode: "NSW",
    RegionId: 15,
    LAT: -33.946111,
    LON: 151.177222,
    IsInternational: true,
    IsGroup: false,
    IsCity: false,
    Weight: 110000,
    ArrivalDateString: null,
    DepartureDate: null,
    DepartureDateString: null,
    CityCode: "SYD",
    Terminal: null,
    TimeZone: "Australia/Sydney",
    Offset: null,
    DstOffset: null,
    CultureId: null,
    Name: "Sydney Kingsford Smith",
    CityName: "Sydney",
    CountryName: "Australia",
    AirportRepresentWholeCity: true,
    IsAirport: true,
    Location: null,
    DisplayName: "SYD - Sydney Kingsford Smith",
    DisplayNameOnPriceDetails: "SYD, Sydney Kingsford Smith",
  },
  // More entries can be added as needed.
];
export default airports;
