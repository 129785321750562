// Add dummy payment status data
const paymentStatusDummyData = [
  { label: "Paid", value: "paid" },
  { label: "Pending", value: "pending" },
  { label: "Not Paid", value: "not_paid" },
  { label: "OverPaid", value: "over_paid" },
];

export default paymentStatusDummyData;

// Add dummy payment status data with updated keys
export const paymentStatusDummyDataWithId = [
  { name: "Paid", id: "paid" },
  { name: "Pending", id: "pending" },
  { name: "Not Paid", id: "not_paid" },
  { name: "OverPaid", id: "over_paid" },
];
