import Widget from "components/widget/Widget";
import { selectUserInfo } from "../../../redux/userSlice";
import PieChartCard from "../default/components/PieChartCard";
import Banner from "./components/Banner";
import Project from "./components/Project";
import { useSelector } from "react-redux";
import { FaHotel, FaMapMarkedAlt, FaUserTie } from "react-icons/fa";
import { RiMessage2Line } from "react-icons/ri";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import userResources from "api/userResources";
import { IoMdAirplane } from "react-icons/io";
import Dashboard from "../default";

const ProfileOverview = () => {
  const userInfo = useSelector(selectUserInfo);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5">
        <div className="col-span-4 lg:!mb-0">
          <Banner data={userInfo?.data} />
        </div>
      </div>

      <Dashboard
        ProfileData={{ profileData: true }}
        assignListNavigate={"/admin/assigned-enquiry-list"}
        createdListNavigate={"/admin/created-enquiry-list"}
        hotelprofileData={{ hotelprofileData: true }}
        hotelassignListNavigate={"/admin/hotel-assigned-enquirys"}
        hotelcreatedListNavigate={"/admin/hotel-created-enquirys"}
      />

      {/* <motion.div
        className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants}>
          <Widget
            heading={"Flight"}
            icon={
              <IoMdAirplane className="h-7 w-7 text-purple-500 dark:text-white" />
            }
            title={"Total Enquiries"}
            subtitle={`${data?.totalEnquiries || 0}`}
            cardbgColor={"bg-purple-500 cursor-pointer"}
            titleColor={"text-gray-900 dark:text-white"}
            navigateTo={"/admin/profile/flight-statistics"}
            data={data}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Widget
            heading={"Hotel"}
            icon={
              <FaHotel className="h-7 w-7 text-green-500 dark:text-white" />
            }
            title={"Total Enquiries"}
            subtitle={`${data?.totalEnquiries || 0}`}
            cardbgColor={"bg-green-500 cursor-pointer"}
            titleColor={"text-gray-900 dark:text-white"}
            navigateTo={"/admin/profile/hotel-statistics"}
            data={data}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Widget
            heading={"Packages"}
            icon={
              <FaMapMarkedAlt className="h-7 w-7 text-yellow-500 dark:text-white" />
            }
            title={"Total Enquiries"}
            subtitle={`${data?.totalEnquiries || 0}`}
            cardbgColor={"bg-yellow-500 cursor-pointer"}
            titleColor={"text-gray-900 dark:text-white"}
            navigateTo={"/admin/profile/packages-statistics"}
            data={data}
          />
        </motion.div>
      </motion.div> */}

      {/* <div>
        <div>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6"
          >
            {data?.totalBudget >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <AiFillDollarCircle className="h-7 w-7 text-blue-500 dark:text-white" />
                  }
                  heading={"Total Amount"}
                  subtitle={`$${data?.totalBudget || 0}`}
                  cardbgColor={"bg-blue-500 cursor-pointer"}
                  titleColor={"text-white"}
                />
              </motion.div>
            ) : null}
            {data?.totalPaidAmount >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <AiFillDollarCircle className="h-7 w-7 text-gray-500 dark:text-white" />
                  }
                  heading={"Total Paid Amount"}
                  subtitle={`$${data?.totalPaidAmount || 0}`}
                  cardbgColor={"bg-gray-500 cursor-pointer"}
                  titleColor={"text-white"}
                  navigateTo={"/admin/assigned-enquiry-list"}
                  id={6}
                  name={"Paid"}
                  thisSearchFor={{
                    name: "enquiryPaymentStatus",
                  }}
                />
              </motion.div>
            ) : null}
            {data?.pendingAmount >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <AiFillDollarCircle className="h-7 w-7 text-pink-500 dark:text-white" />
                  }
                  heading={"Pending Amount"}
                  subtitle={`$${data?.pendingAmount || 0}`}
                  cardbgColor={"bg-pink-500 cursor-pointer"}
                  titleColor={"text-white"}
                  navigateTo={"/admin/assigned-enquiry-list"}
                  id={5}
                  name={"Pending"}
                  thisSearchFor={{
                    name: "enquiryPaymentStatus",
                  }}
                />
              </motion.div>
            ) : null}

            {data?.lastMonthEarnings >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <AiFillDollarCircle className="h-7 w-7 text-yellow-500 dark:text-white" />
                  }
                  heading={"Last Month Earnings"}
                  subtitle={`$${data?.lastMonthEarnings || 0}`}
                  cardbgColor={"bg-yellow-500 cursor-pointer"}
                  titleColor={"text-white"}
                />
              </motion.div>
            ) : null}
            {data?.totalEnquiries >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <RiMessage2Line className="h-7 w-7 text-red-500 dark:text-white" />
                  }
                  heading={"Total Enquiries"}
                  subtitle={`${data?.totalEnquiries || 0}`}
                  cardbgColor={"bg-red-500 cursor-pointer"}
                  titleColor={"text-white"}
                  navigateTo={"/admin/assigned-enquiry-list"}
                />
              </motion.div>
            ) : null}
            {data?.pending_enquiries >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <RiMessage2Line className="h-7 w-7 text-purple-500 dark:text-white" />
                  }
                  heading={"Pending Enquiries"}
                  subtitle={`${data?.pending_enquiries || 0}`}
                  cardbgColor={"bg-purple-500 cursor-pointer"}
                  titleColor={"text-white"}
                />
              </motion.div>
            ) : null}
            {data?.totalEmployees >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <RiMessage2Line className="h-7 w-7 text-indigo-900 dark:text-white" />
                  }
                  heading={"Total Employees"}
                  subtitle={`${data?.totalEmployees || 0}`}
                  cardbgColor={"bg-indigo-900 dark:bg-white cursor-pointer"}
                  titleColor={"text-white dark:text-gray-900"}
                  navigateTo={"/admin/employee-list"}
                />
              </motion.div>
            ) : null}
            {data?.totalUsers >= 0 ? (
              <motion.div variants={cardVariants}>
                <Widget
                  icon={
                    <FaUserTie className="h-7 w-7 text-green-500 dark:text-white" />
                  }
                  heading={"Total Website Users"}
                  subtitle={`${data?.totalUsers || 0}`}
                  cardbgColor={"bg-green-500 cursor-pointer"}
                  titleColor={"text-white"}
                />
              </motion.div>
            ) : null}
          </motion.div>
        </div>

        <div className="mt-0 sm:mt-5 mx-2">
          <div className="grid grid-cols-1 items-center gap-5 md:grid-cols-2">
            {data?.enquirySourceCount && (
              <PieChartCard
                heading={"Enquiry Source Status"}
                data={data?.enquirySourceCount}
                name="enquiry_source"
                navigateTo="/admin/assigned-enquiry-list"
                lastName="name"
                colors={[
                  "#eab308",
                  "#f53939",
                  "#22c55e",
                  "#312e81",
                  "#ff0080",
                  "#a855f7",
                  "#ff0080",
                  "#00bcd4",
                  "#8bc34a",
                ]} 
                thisSearchFor={{
                  name: "enquirySourceStatus",
                }}
              />
            )}
            {data?.airLines && (
              <PieChartCard
                heading={"AirLines"}
                data={data?.airLines}
                name="airLine"
                lastName="airline_name"
                navigateTo="/admin/assigned-enquiry-list"
                colors={[
                  "#3f51b5",
                  "#f44336",
                  "#4caf50",
                  "#ffeb3b",
                  "#ff9800",
                  "#9c27b0",
                  "#009688",
                  "#cddc39",
                  "#673ab7",
                  "#e91e63",
                  "#8bc34a",
                  "#2196f3",
                  "#ffc107",
                  "#607d8b",
                  "#ff5722",
                  "#00bcd4",
                  "#795548",
                  "#03a9f4",
                  "#9e9e9e",
                  "#ff4081",
                  "#5c6bc0",
                  "#d32f2f",
                  "#388e3c",
                  "#ffa726",
                  "#6a1b9a",
                  "#00796b",
                  "#c0ca33",
                  "#4527a0",
                  "#c2185b",
                  "#689f38",
                  "#1976d2",
                  "#fbc02d",
                  "#455a64",
                  "#bf360c",
                  "#00acc1",
                  "#4e342e",
                  "#0288d1",
                  "#757575",
                  "#f50057",
                  "#3949ab",
                  "#b71c1c",
                  "#2e7d32",
                  "#fb8c00",
                  "#4a148c",
                  "#004d40",
                  "#827717",
                  "#311b92",
                  "#880e4f",
                  "#33691e",
                  "#1e88e5",
                ]}
                thisSearchFor={{
                  name: "airlinesStatics",
                }}
              />
            )}
            {data?.enquiryPaymentStatus && (
              <PieChartCard
                heading={"Enquiry Payment Status"}
                data={data?.enquiryPaymentStatus}
                name="enquiryPaymentStatus"
                navigateTo="/admin/assigned-enquiry-list"
                lastName="name"
                colors={["#f53939", "#eab308", "#22c55e"]} 
                thisSearchFor={{
                  name: "enquiryPaymentStatus",
                }}
              />
            )}
          </div>
        </div>
      </div> */}

      {/* all project & ... */}
      {/* <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Project />
        </div>
      </div> */}
    </div>
  );
};

export default ProfileOverview;
